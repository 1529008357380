import { useQuery } from '@tanstack/react-query'
import { httpPOST } from 'lib/http'
import { checkURL } from 'lib/utilities'

export interface IAboutUs {
  ShowInFooter: boolean
  Slug: string
  TabHeader: string
}

export interface IFooter {
  Menu_1: {
    FooterItem: Array<{
      DisplayText: string
      File: {
        data: string
      }
      URL: string
      id: string
    }>
    Header: string
  }

  Menu_2: {
    FooterItem: Array<{
      DisplayText: string
      File: {
        data: string
      }
      URL: string
      id: string
    }>
    Header: string
  }
}

export interface IFooterResponse {
  aboutUsStatics: { data: [{ id: number; attributes: IAboutUs }] }
  footer: { data: { attributes: IFooter } }
}

/* API Request */
export const getFooterDetails = async () => {
  const footerResp = await httpPOST({
    endpoint: 'cms',
    url: 'graphql',
    body: JSON.stringify({
      query: `
      query {
        footer {
          data {
            attributes {
              Menu_1 {
                Header
                FooterItem {
                  id
                  DisplayText
                  URL
                  File {
                    data {
                      attributes {
                        provider_metadata
                      }
                    }
                  }
                }
                
              }
              Menu_2 {
                Header
                FooterItem {
                  id
                  DisplayText
                  URL
                  File {
                    data {
                      attributes {
                        provider_metadata
                      }
                    }
                  }
                }
                
              }
            }
          }
        }

        aboutUsStatics(pagination: { limit: 10000 }, sort: "createdAt:desc", filters: { ShowInFooter: { eq: true}}){
          data {
            id,
            attributes {
              Slug
              ShowInFooter
              TabHeader
            }
          }
        }
      }
        `
    })
  })

  const response = footerResp?.data as IFooterResponse

  const aboutUs = response?.aboutUsStatics?.data?.map(item => ({
    id: item?.id,
    label: item?.attributes?.TabHeader,
    href: `/about-us/${item?.attributes?.Slug}`
  }))

  const getHref = (
    item: {
      DisplayText: string
      File: {
        data: any
      }
      URL: string
      id: string
    },
    checkURLRes: {
      originalUrl: string | null
      baseURL: string | null
      restUrlWithQuery: string | null
      isInsideUrl: boolean | null
    }
  ) => {
    if (item?.URL) {
      return checkURLRes?.isInsideUrl ? checkURLRes?.restUrlWithQuery : item?.URL
    } else if (item?.File?.data?.attributes?.provider_metadata?.public_id) {
      return `/viewer/${item?.File?.data?.attributes?.provider_metadata?.public_id}`
    }
    return ''
  }

  const menu1Items = response?.footer?.data?.attributes?.Menu_1?.FooterItem?.map((item: any) => {
    const res = checkURL(item?.URL)
    return {
      id: item?.id,
      label: item?.DisplayText,
      href: getHref(item, res),
      target: (item?.URL && !res?.isInsideUrl) || item?.File?.data ? '_blank' : ''
    }
  })

  const menu2Items = response?.footer?.data?.attributes?.Menu_2?.FooterItem?.map((item: any) => {
    const res = checkURL(item?.URL)
    return {
      id: item?.id,
      label: item?.DisplayText,
      href: getHref(item, res),
      target: item?.URL && res?.isInsideUrl ? '' : '_blank'
    }
  })

  return {
    menu1: { header: response?.footer?.data?.attributes?.Menu_1?.Header, items: menu1Items },
    menu2: {
      header: response?.footer?.data?.attributes?.Menu_2?.Header || 'About Us',
      items: menu2Items !== undefined ? [...aboutUs, ...menu2Items] : aboutUs
    }
  }
}

export const useGetFooter = () => {
  return useQuery({
    queryKey: ['GET_FOOTER'],
    queryFn: async () => {
      const categories = await getFooterDetails()
      return categories
    },
    enabled: true,
    refetchOnWindowFocus: true,
    refetchOnMount: true
  })
}
