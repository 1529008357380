import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { FC, useEffect, useRef } from 'react'
import Skeleton from 'react-loading-skeleton'
import { Button } from '@nzsb/shopnx-ui'
import CN from 'classnames'
import { IAllCategory } from 'lib/actions'
import { useModalContext } from 'lib/contexts/modal-context'

export interface MegaMenuSubCategorySectionProps {
  categoryItems?: IAllCategory | null
  categorySlug?: string
  className?: string
  componentId?: string
  headerTitle?: string
  iconName?: string
  isLoading?: boolean
  onClose?: () => void
}

export const MegaMenuSubCategorySection: FC<MegaMenuSubCategorySectionProps> = ({
  categoryItems,
  categorySlug,
  className,
  componentId,
  headerTitle,
  iconName,
  isLoading = false,
  onClose,
  ...restProps
}: MegaMenuSubCategorySectionProps) => {
  const MegaMenuSubCategorySectionClasses = CN(
    `hidden md:block md:w-[426px] lg:w-[880px] bg-white`,
    className
  )

  const router = useRouter()

  const { megaMenuModal } = useModalContext()

  /** Getting category slug to highlight the sub category if it is being currently visited  */
  const slug = router.query.slug?.[2] as string

  const divRef = useRef<HTMLDivElement | null>(null) // Function to scroll to the top of the div
  const scrollToTop = () => {
    if (divRef.current) {
      divRef.current.scrollTop = 0
    }
  }

  useEffect(() => {
    scrollToTop()
  }, [categoryItems])

  return (
    <>
      {isLoading ? (
        <Skeleton
          data-component-id={`${componentId}-sub-category-section-loader`}
          count={1}
          width={'100%'}
          height={300}
          containerClassName='flex flex-col'
        />
      ) : (
        <div
          data-component-id={`${componentId}`}
          className={MegaMenuSubCategorySectionClasses}
          {...restProps}>
          {/** Modal Header Section */}
          <div
            data-component-id={`${componentId}-header`}
            className='flex justify-between gap-x-3 mb-3'>
            {iconName && (
              <i
                data-component-id={`${componentId}-header-icon`}
                className={`${iconName} lg:text-[48px] md:text-[36px] text-B-500`}
              />
            )}

            <div
              data-component-id={`${componentId}-header-title`}
              className='md:w-[346px] lg:w-[788px] uppercase font-display font-400 lg:text-display-h2 md:text-display-h2-m text-B-500'>
              {headerTitle}
            </div>

            <button
              tabIndex={0}
              data-component-id={`${componentId}-header-close-btn`}
              className='flex text-[20px] text-N-700 cursor-pointer'
              onClick={onClose}
              onKeyDown={e => {
                if (onClose && e.key === 'Enter') {
                  onClose()
                }
              }}>
              <i className='nzsbi-close' />
            </button>
          </div>

          <div
            className='md:max-h-[506px] lg:!max-h-[calc(100vh-384px)] overflow-y-auto overflow-x-hidden'
            ref={divRef}>
            {/** Sub Category Section Tiles */}
            <div style={{ columnCount: 3 }} className='md:grid lg:block lg:pt-[14px] md:pt-3'>
              {Array.isArray(categoryItems) &&
                categoryItems?.map((item: any, index: number) => (
                  <div
                    key={`${item?.name}-${index}-container`}
                    className='grid grid-rows-[1fr,auto] mb-6 break-inside-avoid'>
                    <div
                      data-component-id={`${componentId}-item-${index}`}
                      className='max-w-full block row-span-full col-span-1 border-t-[1px] border-B-200 md:flex-none lg:flex-1 md:grow-0 lg:grow-1 md:shrink-0 lg:shrink-1 md:w-[406px] lg:w-[260px]'
                      key={`${item?.name}-${index}-sub-container`}>
                      <div
                        data-component-id={`${componentId}-item-${index}-header`}
                        className='flex justify-between px-3 pt-4 pb-2'>
                        <a
                          data-component-id={`${componentId}-item-${index}-header-text`}
                          className='font-700 text-sm text-N-800 max-w-[190px]'
                          href={`/category/${categorySlug}/${item?.urlSlug}`}
                          onClick={() => {
                            megaMenuModal.close()
                          }}>
                          {item?.name}
                        </a>
                      </div>

                      <div
                        data-component-id={`${componentId}-item-${index}-sub-category`}
                        className='flex flex-col text-sm font-400 text-N-700'>
                        {item?.childCategories?.map((subCategory: any, subIndex: number) => (
                          <Link
                            href={`/category/${categorySlug}/${item?.urlSlug}/${subCategory?.urlSlug}`}
                            onClick={() => {
                              megaMenuModal.close()
                            }}
                            data-component-id={`${componentId}-item-${index}-sub-category-${subCategory?.id}`}
                            className={CN(`px-3 py-2 hover:bg-B-25`, {
                              'text-B-500 font-600': slug === subCategory?.urlSlug
                            })}
                            key={subCategory?.id ?? subIndex}>
                            {subCategory?.name}
                          </Link>
                        ))}
                      </div>

                      <Button
                        className='w-fit justify-self-end mr-4'
                        isBlock={false}
                        isRingOffset={false}
                        onClick={() => {
                          router.push(`/category/${categorySlug}/${item?.urlSlug}`)
                          megaMenuModal.close()
                        }}
                        componentId={`${componentId}-item-${index}-header-see-all-btn`}
                        appearance='link-gray'>
                        See All
                      </Button>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default MegaMenuSubCategorySection
