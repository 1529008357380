import Link from 'next/link'
import React, { FC } from 'react'
import { Button } from '@nzsb/shopnx-ui'
import CN from 'classnames'
import { IBrandList, IBrandLogoItem } from 'lib/actions'
import { checkURL } from 'lib/utilities'

import { NextImage } from 'components/atoms'
export interface IServicesBrandBlockProps {
  className?: string
  componentId?: string
  data: IBrandList
}

export const ServicesBrandBlock: FC<IServicesBrandBlockProps> = ({
  className,
  componentId,
  data,
  ...restProps
}: IServicesBrandBlockProps) => {
  const ServicesBrandBlockClasses = CN(
    `${componentId}-brand-logo-list gap-[32px] flex flex-col`,
    className
  )

  const {
    originalUrl: buttonOriginalUrl,
    restUrlWithQuery: buttonQuery,
    isInsideUrl: buttonUrlIsInternal
  } = checkURL(data?.Action?.URL ?? '')

  const getLink = () => {
    if (data?.Action?.URL) {
      if (buttonUrlIsInternal) {
        return buttonQuery
      } else {
        return buttonOriginalUrl ?? ''
      }
    } else {
      return '/brands/shop-by-brands'
    }
  }

  const getTarget = () => {
    if (data?.Action?.URL && !buttonUrlIsInternal) {
      return '_blank'
    } else {
      return ''
    }
  }

  return (
    <div className={ServicesBrandBlockClasses} {...restProps}>
      <div className='flex flex-col justify-start w-full gap-y-2 gap-x-6 md:!flex-row md:!justify-between md:items-end'>
        {data?.Header && (
          <div data-component-id='popular-brand-header' className='font-600 text-h3 text-N-800'>
            {data?.Header}
          </div>
        )}
        {data?.Action?.DisplayText && (
          <Link href={getLink()} passHref target={getTarget()}>
            <Button
              componentId={`${componentId}-action-button`}
              className='w-fit'
              appearance='link'
              size='md'
              iconAfter='nzsbi-chevron-right'>
              {data?.Action?.DisplayText}
            </Button>
          </Link>
        )}
      </div>

      <div className='grid grid-cols-2 md:grid-cols-4 gap-4 lg:!gap-8 md:!gap-x-5 md:!gap-y-6'>
        {data?.Brands?.data.map((item: IBrandLogoItem, index: any) => {
          let url: any
          let isInside: any
          const linkToCuration = item?.attributes?.LinkToSearchCuration

          if (item?.attributes?.LinkToSearchCuration) {
            const urlProps = checkURL(item?.attributes?.SearchCurationURL)
            isInside = urlProps?.isInsideUrl
            url = urlProps?.originalUrl
          } else {
            url = item?.attributes?.SearchCurationURL
            isInside = true
          }
          return (
            <Link
              passHref
              target={isInside ? '' : '_blank'}
              aria-label={`brand-image-${JSON.parse(item?.attributes?.Brand)?.value}`}
              href={
                linkToCuration
                  ? url
                  : `/brands/shop-by-brands/${JSON.parse(item?.attributes?.Brand)?.value}`
              }
              key={item?.attributes?.BrandID}
              className={CN({
                'pointer-events-none': !(url || JSON.parse(item?.attributes?.Brand)?.value)
              })}>
              <div className='border border-N-100 rounded-s flex px-[24px] py-[8px] w-full h-[96px] relative'>
                <NextImage
                  nextImgStyle={{ padding: '8px 24px', objectFit: 'contain' }}
                  data-component-id={`${componentId}-brand-banner-image`}
                  imgKey={`${item?.attributes?.BrandID ?? ''}-${index}`}
                  imageUrl={
                    item?.attributes?.BrandLogo?.data?.attributes?.provider_metadata?.public_id ||
                    ''
                  }
                  system='CMS'
                  useWithDimensions={false}
                  alt={
                    item?.attributes?.BrandLogo?.data?.attributes?.alternativeText ||
                    item.attributes.BrandName ||
                    ''
                  }
                  {...restProps}
                />
              </div>
            </Link>
          )
        })}
      </div>
    </div>
  )
}

export default ServicesBrandBlock
