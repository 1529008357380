export const checkURL = (url: any) => {
  if (url) {
    const prepUrl = !url.includes('http') ? `https://${url}` : (url as string)
    const urlObject = new URL(prepUrl)
    const baseURL = `${urlObject.protocol}//${urlObject.host}`
    const restUrlWithQuery = `${urlObject.pathname}${urlObject.search}`
    const isInsideUrl = baseURL === process.env.NEXT_PUBLIC_INTERNAL_API_URI

    return { originalUrl: prepUrl, baseURL, restUrlWithQuery, isInsideUrl }
  }

  return {
    originalUrl: null,
    baseURL: null,
    restUrlWithQuery: null,
    isInsideUrl: null
  }
}

export default checkURL
