import crypto from 'crypto'

const algorithm = process.env.NEXT_PUBLIC_CRYPTO_ALGORITHM as crypto.CipherGCMTypes
const key = process.env.NEXT_PUBLIC_CRYPTO_KEY as string
const iv = process.env.NEXT_PUBLIC_CRYPTO_IV as string

export const encrypt = (text: string) => {
  const cipher = crypto.createCipheriv(algorithm, Buffer.from(key), iv)
  let encrypted = cipher.update(text)
  encrypted = Buffer.concat([encrypted, cipher.final()])
  return encrypted.toString('hex')
}

export default encrypt
