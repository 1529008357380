import { useQuery } from '@tanstack/react-query'
import { httpPOST } from 'lib/http'

export interface IGetSelectedStatementsListReq {
  statementList: Array<string>
}

export interface ISelectedStatement {
  customerNumber: string
  statementYear: number
  statementMonth: number
  statementPeriod: number
  balance: number
  accountNumber: string
  status: string
}

export interface IGetSelectedStatementsListRes {
  messageTitle: string | null
  messageDescription: string | null
  messageType: string | null
  messageCode: string | null
  data: {
    statementList: Array<ISelectedStatement>
  } | null
}

export const getSelectedStatementList = async (req: IGetSelectedStatementsListReq) => {
  const { data } = await httpPOST({
    url: 'statement/get_selected_statement_list',
    body: req
  })
  return data as IGetSelectedStatementsListRes
}

export const useGetSelectedStatementsList = (req: IGetSelectedStatementsListReq) => {
  const statements = req?.statementList ?? []

  return useQuery({
    queryKey: ['GET_SELECTED_STATEMENT_LIST', ...statements],
    queryFn: () => getSelectedStatementList(req),
    enabled: false
  })
}
