import Link from 'next/link'
import React, { FC } from 'react'
import { Lozenge } from '@nzsb/shopnx-ui'
import CN from 'classnames'
import { IProductCardData } from 'lib/actions'
import { checkURL } from 'lib/utilities'

import { ProductImageCarousel } from 'components/organisms'

export interface ProductNoticeCardProps {
  className?: string
  componentId?: string
  productCardData?: IProductCardData['attributes']
}

export const ProductNoticeCard: FC<ProductNoticeCardProps> = ({
  className,
  componentId,
  productCardData,
  ...restProps
}: ProductNoticeCardProps) => {
  const {
    originalUrl: buttonOriginalUrl,
    restUrlWithQuery: buttonQuery,
    isInsideUrl: buttonUrlIsInternal
  } = checkURL(productCardData?.Action?.URL ?? '')

  const ProductNoticeCardClasses = CN(`border border-N-100 rounded shadow-sm`, className)

  const getLozengeVariant = () => {
    switch (productCardData?.NoticeType) {
      case 'Information_Blue_Label':
        return 'primary'
      case 'Caution_Yellow_Label':
        return 'warning-1'
      case 'Critical_Red_Label':
        return 'danger'
      default:
        return 'primary'
    }
  }

  const getLink = () => {
    if (productCardData?.Action?.URL && buttonUrlIsInternal) {
      return buttonQuery ?? ''
    } else if (productCardData?.Action?.URL && !buttonUrlIsInternal) {
      return buttonOriginalUrl ?? ''
    } else if (!productCardData?.Action?.URL && productCardData?.Action?.File?.data) {
      return `/viewer/${productCardData?.Action?.File?.data?.attributes?.provider_metadata?.public_id}`
    } else {
      return '/'
    }
  }

  return (
    <div className={ProductNoticeCardClasses} {...restProps}>
      <div className='flex flex-col md:flex-row'>
        {/** Product Image */}
        <div
          data-component-id={`${componentId}-item-image-section`}
          className='flex items-center justify-center w-full h-[216px] md:!w-[216px] p-2'>
          <ProductImageCarousel productImages={productCardData?.Images?.data || []} />
        </div>

        {/** Card Content */}
        <div className='flex flex-col p-4' data-component-id={`${componentId}-item-content`}>
          {/** Lozenge badge */}
          {productCardData?.NoticeText && (
            <Lozenge componentId={`${componentId}-item-lozenge`} variant={getLozengeVariant()}>
              {productCardData?.NoticeText}
            </Lozenge>
          )}

          {/** Product Title */}
          <span
            data-component-id={`${componentId}-item-title`}
            className='font-600 text-h3 text-N-700 pt-2'>
            {productCardData?.Header}
          </span>

          {/* Commented this BCZ sometimes may have new field from CMS for the posted date in future */}
          {/** Posted Date */}
          {/* <span
            data-component-id={`${componentId}-item-posted-date`}
            className='font-500 text-sm text-B-500'>
            Posted on {formatToDMYConversion(productCardData?.createdAt ?? '')}
          </span> */}

          {/** Product Description */}
          {productCardData?.Description && (
            <span
              data-component-id={`${componentId}-item-description`}
              className='font-400 text-base text-N-700 mt-2 max-h-[300px] overflow-auto'>
              <div
                data-component-id='content-html-seo-description'
                dangerouslySetInnerHTML={{ __html: productCardData?.Description }}
                className='prose mark-down max-w-none'
              />
            </span>
          )}

          {/** Card Action Button */}
          {(productCardData?.Action?.File?.data || productCardData?.Action?.URL) && (
            <Link
              href={getLink()}
              passHref
              target={productCardData?.Action?.URL && buttonUrlIsInternal ? '' : '_blank'}
              className={CN('font-500 text-base text-O-500 pt-4 underline', {
                'cursor-pointer': buttonOriginalUrl || productCardData?.Action?.File?.data,
                'cursor-auto pointer-events-none':
                  !buttonOriginalUrl && !productCardData?.Action?.File?.data
              })}
              data-component-id={`${componentId}-item-action-btn`}>
              {productCardData?.Action?.DisplayText}
            </Link>
          )}
        </div>
      </div>
    </div>
  )
}

export default ProductNoticeCard
