import { useRouter } from 'next/router'
import React, { FC, useState } from 'react'
import { AlertProps, ProdAccessoryCard, Toast } from '@nzsb/shopnx-ui'
import { useAddToCart, useAddToFavoriteProduct, useDeleteFavoriteProduct } from 'lib/actions'
import { useAppContext } from 'lib/contexts/app-context'
import { useModalContext } from 'lib/contexts/modal-context'
import { checkRoleBasedProperties } from 'lib/utilities'

import { NextImage } from 'components/atoms'

export interface IProductAccessoryCardProps {
  className?: string
  productData?: any
  appearance?: 'horizontal' | 'vertical'
  isLoggedIn?: boolean
  onFavoriteActionClick?: any
  [key: string]: any
}

/** Render Image with Next Image component */
const RenderImage = (productInformation: any, navigateToProductDetails: any) => {
  return (
    <button
      onClick={navigateToProductDetails}
      className='relative block w-full h-[88px] sm:h-[124px]'>
      <NextImage
        imgKey={`${productInformation?.sku ?? ''}-${productInformation?.id ?? ''}`}
        width={600}
        height={600}
        imageUrl={productInformation?.image?.url}
        alt={productInformation?.image?.alt || productInformation?.title}
      />
    </button>
  )
}

export const ProductAccessoryCard: FC<IProductAccessoryCardProps> = ({
  className,
  productData,
  appearance,
  ...restProps
}: IProductAccessoryCardProps) => {
  const router = useRouter()

  const notify = (props: AlertProps) => Toast(props)

  const { bulkOrderModal } = useModalContext()
  const { productVariants, isLoggedIn, user } = useAppContext()

  const { addToCartAsync, isPending: isLoadingAddToCart } = useAddToCart()
  const { mutate: addToFavoriteAction } = useAddToFavoriteProduct()
  const { mutate: deleteFavoriteProduct } = useDeleteFavoriteProduct()

  const [product, setProduct] = useState(productData || [])

  const addItemsToCart = () => {
    //setIsLoading(true)
    addToCartAsync({ productId: product?.id, quantity: 1 }).then(res => {
      if (res?.data?.validationMessage) {
        notify({
          status: res?.data?.validationMessage?.messageType,
          alertHeader: res?.data?.validationMessage?.title,
          alertBody: res?.data?.validationMessage?.message
        })
      }
    })
  }

  const addToCartAction = () => {
    if (productData?.isEnrolled) {
      router.push(`/worksafe-training/${productData?.urlSlug}`)
    } else if (productData?.hasVariant) {
      bulkOrderModal.set(true)
      productVariants.setProduct(product)
    } else {
      addItemsToCart()
    }
  }

  const onFavoriteActionClick = (productId: string) => {
    if (product.isFavorite) {
      deleteFavoriteProduct(productId)
    } else {
      addToFavoriteAction(productId)
    }

    setProduct({ ...product, isFavorite: !product.isFavorite })
  }

  const navigateToProductDetails = () => {
    router.push(`/product/${product?.urlSlug}`)
  }

  const { showGstPricePrimary } = checkRoleBasedProperties(user?.data?.roles)

  return (
    <ProdAccessoryCard
      key={`${product?.sku}-${product?.id}`}
      nextImage={RenderImage(product, navigateToProductDetails)}
      title={product.title}
      sku={product.sku}
      priceLabel={{
        price: {
          price: product.price,
          withGst: product.priceWithGst,
          saveUpTo: 0.0
        },
        showGstPricePrimary
      }}
      onTitleClick={navigateToProductDetails}
      isEnrolled={product.isEnrolled}
      isShowWishList={isLoggedIn && !product.isEnrolled}
      isOnWishlist={product.isFavorite}
      onClickAddToCart={addToCartAction}
      onClickWishlist={() => onFavoriteActionClick(product?.id)}
      isLoading={isLoadingAddToCart}
      {...restProps}
    />
  )
}

export default ProductAccessoryCard
