import { useRouter } from 'next/router'
import React, { FC } from 'react'
import { Button, Lozenge, Modal, ModalBody, ModalHeader } from '@nzsb/shopnx-ui'
import CN from 'classnames'
import { useAppContext } from 'lib/contexts/app-context'
import { useModalContext } from 'lib/contexts/modal-context'
import { checkDateDiff, checkRoleBasedProperties } from 'lib/utilities'
import { convertDate } from 'lib/utilities/convertDate'

export interface IExpectedDispatchModalProps {
  className?: string
}

export const ExpectedDispatchModal: FC<IExpectedDispatchModalProps> = ({
  className,

  ...restProps
}: IExpectedDispatchModalProps) => {
  const ExpectedDispatchModalClasses = CN(`expected-dispatch-modal`, className)

  const { expectedDispatchModal, bulkOrderModal } = useModalContext()
  const { isOpen, size, color, sku, stock, close, name, store, expectedDispatchDate } =
    expectedDispatchModal

  const { user } = useAppContext()

  const { isRedirectToMyOrderPage } = checkRoleBasedProperties(user?.data?.roles)

  const router = useRouter()
  const onEnquireClick = () => {
    router.push(
      `mailto:website@nzsafetyblackwoods.co.nz?subject=Customer%20product%20availability%20inquiry.%20PC-${sku}`
    )
  }

  const onMyOrderClick = () => {
    close()
    bulkOrderModal.close()
    if (isRedirectToMyOrderPage) {
      router.push('/my-account/my-orders')
    } else {
      router.push('/coming-soon')
    }
  }

  return (
    <div className={ExpectedDispatchModalClasses} {...restProps}>
      <Modal
        componentId='expected-despatch-modal'
        isOpen={isOpen}
        onClickOverlay={close}
        className='w-[444px] flex-shrink-0'>
        <ModalHeader
          componentId='expected-despatch-modal'
          onClose={close}
          heading={'Expected Despatch'}
          hasBorder={false}
        />
        <ModalBody>
          <div className='text-base font-bold text-N-700 mb-[4px]'>{name}</div>
          <div className='text-sm font-normal text-N-600 mb-[4px]'>{sku}</div>
          {size && color && (
            <div className='flex flex-row text-sm font-normal text-N-600 mb-[16px]'>
              <div className='mr-[24px]'>
                <span>Size: </span>
                <span className='font-bold'>{size}</span>
              </div>
              <div>
                <span>Colour: </span>
                <span className='font-bold'>{color}</span>
              </div>
            </div>
          )}
          <div className='flex flex-row gap-[8px] mt-[16px] text-sm'>
            <Lozenge
              icon='nzsbi-truck'
              variant={stock > 0 ? 'success' : 'warning-2'}
              appearance='subtle'
              className='text-xs uppercase text-start'
            />
            Expected Despatch{' '}
            {stock === 0 && checkDateDiff(expectedDispatchDate, 30)
              ? ' 30+ Days'
              : convertDate(expectedDispatchDate)}
          </div>
          <div className='text-base font-normal text-N-700 mt-[16px]'>
            {stock === 0 && store?.count >= 0 && (
              <>
                <div>Expected despatch dates are indicative based on product lead times.</div>
                <div className='mt-[16px]'>
                  Dates may change and be updated during the fulfilment period and will be updated
                  in your{' '}
                  <Button className='!inline' onClick={onMyOrderClick} appearance='link'>
                    My Orders
                  </Button>{' '}
                  details page.
                </div>
              </>
            )}
            {stock > 0 && (
              <>
                <div>
                  The expected despatch date indicates when this item is next likely to be available
                  for despatch from our Distribution Centre and is subject to change.
                </div>
                <div className='mt-[16px]'>
                  Upon ordering this item, you can keep a track of the changes to the expected
                  despatch date on the order detail page within your{' '}
                  <Button className='!inline' onClick={onMyOrderClick} appearance='link'>
                    My Orders
                  </Button>
                  .
                </div>
              </>
            )}
          </div>
          {stock === 0 && (
            <>
              <div className='mt-[16px]'>
                Enquire for more information relating to the availability of this item
              </div>
              <Button
                appearance='primary-orange'
                isBlock
                className='mt-[16px]'
                onClick={onEnquireClick}>
                Send Product Enquiry
              </Button>
            </>
          )}
        </ModalBody>
      </Modal>
    </div>
  )
}

export default ExpectedDispatchModal
