import { useMutation } from '@tanstack/react-query'
import { httpPOST } from 'lib/http'

export interface ISendInvoiceResponse {
  data: string
  messageDescription: string
  messageTitle: string
  messageType: string
}

export interface ISendEmailRequest {
  cCtoMySelf: boolean
  cCtoNZTeam: boolean
  invoiceList?: string[]
  message: string
  subject: string
  toEmail: string
}

export const sendEmail = async (req: ISendEmailRequest) => {
  const { data } = await httpPOST({
    url: `invoice/send_email_invoice_list`,
    body: req
  })
  return data?.data as ISendInvoiceResponse
}

export const useSendInvoiceEmail = () => {
  return useMutation({
    mutationFn: sendEmail
  })
}
