/* eslint-disable import/no-extraneous-dependencies */
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/navigation'
import React, { FC, useEffect, useRef } from 'react'
import { Button } from '@nzsb/shopnx-ui'
import CN from 'classnames'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Swiper as SwiperCore } from 'swiper/types'
import { checkURL } from 'lib/utilities'

export interface IMiniHeroProps {
  className?: string
  heroSlides: Array<{
    id: number
    Header?: string
    SubHeader: string
    DesktopOverlay: 'None' | 'Very_Light' | 'Light' | 'Medium' | 'Dark'
    MobileOverlay: 'None' | 'Very_Light' | 'Light' | 'Medium' | 'Dark'
    MobileImage: {
      data: {
        id: string
        attributes: {
          name: string
          alternativeText: string | null
          url: string
        }
      }
    }
    DesktopImage: {
      data: {
        id: string
        attributes: {
          name: string
          alternativeText: string | null
          url: string
        }
      }
    }
    ActionButton?: {
      ButtonText?: string
      Url?: string
      IconBefore: string
      IconAfter: string
    }
    RedirectionURL?: string
  }>
}

export const MiniHero: FC<IMiniHeroProps> = ({
  className,
  heroSlides: MiniHeroSlides,
  ...restProps
}: IMiniHeroProps) => {
  const router = useRouter()

  const MiniHeroClasses = CN(`MiniHero flex flex-col gap-[12px] items-center`, className)

  const swiperRef = useRef<SwiperCore>()

  useEffect(() => {
    if (MiniHeroSlides?.length > 1) {
      swiperRef.current?.slideToLoop(0)
    }
  }, [MiniHeroSlides])

  const onButtonClick = (url: string) => {
    const urlObj = new URL(url)
    if (window.location?.origin !== urlObj?.origin) {
      window.open(url, '_blank')
    } else {
      router.push(url)
    }
  }

  return (
    <section className={MiniHeroClasses} {...restProps}>
      <div className='relative w-full h-full flex items-center justify-center md:bg-B-500'>
        {/* MiniHero Left Pagination button */}
        {MiniHeroSlides?.length > 1 && (
          <button
            className='items-center justify-center hidden md:flex absolute top-[50%] left-0 mt-[-24px] w-[40px] h-[40px] bg-B-25 z-50 border border-N-100 rounded'
            onClick={() => swiperRef.current?.slidePrev()}>
            <i className='ri-arrow-left-s-line text-N-700 text-xs lg:!text-[24px]' />
          </button>
        )}

        {/* MiniHero Desktop Slider start */}

        <Swiper
          loop={MiniHeroSlides?.length > 3}
          centeredSlides
          slidesPerView={'auto'}
          className='w-full md:h-[144px] lg:h-[272px] !hidden md:!block'
          onBeforeInit={swiper => {
            swiperRef.current = swiper
          }}>
          {MiniHeroSlides?.map((promo, index) => {
            const { originalUrl, restUrlWithQuery, isInsideUrl } = checkURL(
              promo?.RedirectionURL ?? ''
            )

            return (
              <SwiperSlide
                key={`Desktop - ${promo?.id ?? index}`}
                className={CN('!px-0 flex h-[420px] w-full', {
                  'pl-[12px]': index === 0 && MiniHeroSlides?.length > 1
                })}>
                {({ isActive }) => (
                  <Link
                    href={isInsideUrl ? restUrlWithQuery : originalUrl ?? {}}
                    passHref
                    target={isInsideUrl ? '' : '_blank'}
                    className={CN({
                      'cursor-pointer': originalUrl,
                      'cursor-auto pointer-events-none': !originalUrl
                    })}>
                    {isActive && (
                      <div
                        className={CN(
                          'absolute w-full h-full bg-gradient-to-r from-black z-10 from-50%',
                          {
                            'opacity-0': promo?.DesktopOverlay === 'None',
                            'opacity-10': promo?.DesktopOverlay === 'Very_Light',
                            'opacity-30': promo?.DesktopOverlay === 'Light',
                            'opacity-50': promo?.DesktopOverlay === 'Medium',
                            'opacity-70': promo?.DesktopOverlay === 'Dark'
                          }
                        )}
                      />
                    )}

                    <div className='flex flex-col justify-center absolute top-0 bottom-0 z-10 right-0'>
                      {promo?.Header && (
                        <div className='text-white uppercase z-20 font-display text-display-h1-m lg:text-display-h1 mr-[32px] lg:mr-[64px] text-right'>
                          {promo?.Header}
                        </div>
                      )}
                      {promo?.SubHeader && (
                        <div className='text-white text-display-body lg:text-display-body z-20 mr-[32px] lg:mr-[64px] text-right'>
                          {promo?.SubHeader}
                        </div>
                      )}
                      {promo?.ActionButton?.ButtonText && (
                        <>
                          {/* Button in Tab View */}
                          <Button
                            onClick={() => {
                              if (promo?.ActionButton?.Url) onButtonClick(promo?.ActionButton?.Url)
                            }}
                            size='xs'
                            appearance='primary-orange'
                            iconAfter={promo?.ActionButton?.IconAfter}
                            iconBefore={promo?.ActionButton?.IconBefore}
                            className={CN(
                              'md:block lg:!hidden justify-self-end self-end mt-[12px] mr-[32px] !h-[30px] text-xs',
                              {
                                'pointer-events-auto': !!promo?.ActionButton?.Url
                              }
                            )}>
                            {promo?.ActionButton?.ButtonText}
                          </Button>
                          {/* Button in Desktop View */}
                          <Button
                            onClick={() => {
                              if (promo?.ActionButton?.Url) onButtonClick(promo?.ActionButton?.Url)
                            }}
                            size='md'
                            appearance='primary-orange'
                            iconAfter={promo?.ActionButton?.IconAfter}
                            iconBefore={promo?.ActionButton?.IconBefore}
                            className={CN(
                              'hidden lg:!block justify-self-end self-end mt-[32px] mr-[64px]',
                              {
                                'pointer-events-auto': !!promo?.ActionButton?.Url
                              }
                            )}>
                            {promo?.ActionButton?.ButtonText}
                          </Button>
                        </>
                      )}
                    </div>

                    <Image
                      className='lg:max-w-[1200px]'
                      src={
                        promo?.DesktopImage?.data?.attributes?.url === null
                          ? 'https://via.placeholder.com/72x72/afd8e6/969696?text=No+Image'
                          : promo?.DesktopImage?.data?.attributes?.url
                      }
                      alt={
                        promo?.DesktopImage?.data?.attributes?.alternativeText ?? 'MiniHero Slide'
                      }
                      fill
                      style={{ objectFit: 'cover' }}
                      placeholder='blur'
                      blurDataURL={
                        promo?.DesktopImage?.data?.attributes?.url ??
                        'https://via.placeholder.com/72x72/afd8e6/969696'
                      }
                    />
                  </Link>
                )}
              </SwiperSlide>
            )
          })}
        </Swiper>

        {/* MiniHero Desktop Slider end */}
        {/* MiniHero Desktop pagination right button */}
        {MiniHeroSlides?.length > 1 && (
          <button
            className='hidden md:flex justify-center items-center absolute mt-[-24px] top-[50%] right-0 w-[40px] h-[40px] bg-B-25 z-40 border-1 border-N-100 rounded'
            onClick={() => swiperRef.current?.slideNext()}>
            <i className='ri-arrow-right-s-line text-N-700  text-xs lg:!text-[24px]' />
          </button>
        )}

        {/* MiniHero Mobile Slider start */}
        <Swiper
          spaceBetween={14}
          slidesPerView={'auto'}
          className='w-full h-full block md:!hidden mt-[40px] mb-[40px]'>
          {MiniHeroSlides?.map((promo, index) => {
            const { originalUrl, restUrlWithQuery, isInsideUrl } = checkURL(
              promo?.RedirectionURL ?? ''
            )

            return (
              <SwiperSlide
                key={`mobile - ${promo?.id ?? index}`}
                className={CN('max-w-[288px] rounded flex min-h-[352px]', {
                  'ml-[12px]': index === 0
                })}>
                <Link
                  href={isInsideUrl ? restUrlWithQuery : originalUrl ?? {}}
                  passHref
                  target={isInsideUrl ? '' : '_blank'}
                  className={CN({
                    'cursor-pointer': originalUrl,
                    'cursor-auto pointer-events-none': !originalUrl
                  })}>
                  <div
                    className={CN(
                      'absolute w-full h-full bg-red bg-gradient-to-r from-black z-10 from-50%',
                      {
                        'opacity-0': promo?.MobileOverlay === 'None',
                        'opacity-10': promo?.MobileOverlay === 'Very_Light',
                        'opacity-30': promo?.MobileOverlay === 'Light',
                        'opacity-50': promo?.MobileOverlay === 'Medium',
                        'opacity-70': promo?.MobileOverlay === 'Dark'
                      }
                    )}
                  />
                  <div className='absolute bottom-0 flex flex-col justify-end p-[24px] z-10'>
                    {promo?.Header && (
                      <div className='text-white z-10 font-display text-display-h2 md:text-display-h1 '>
                        {promo?.Header}
                      </div>
                    )}
                    {promo?.SubHeader && (
                      <div className='text-white mt-[12px] text-display-body'>
                        {promo?.SubHeader}
                      </div>
                    )}
                    {promo?.ActionButton?.ButtonText && (
                      <Button
                        onClick={() => {
                          if (promo?.ActionButton?.Url) onButtonClick(promo?.ActionButton?.Url)
                        }}
                        size='xs'
                        appearance='primary-orange'
                        iconAfter={promo?.ActionButton?.IconAfter}
                        iconBefore={promo?.ActionButton?.IconBefore}
                        className={CN('w-fit mt-[10px] !h-[30px] text-xs', {
                          'pointer-events-auto': !!promo?.ActionButton?.Url
                        })}>
                        {promo?.ActionButton?.ButtonText}
                      </Button>
                    )}
                  </div>

                  <Image
                    className='rounded'
                    src={
                      promo?.MobileImage?.data?.attributes?.url === null
                        ? 'https://via.placeholder.com/72x72/afd8e6/969696?text=No+Image'
                        : promo?.MobileImage?.data?.attributes?.url
                    }
                    alt={promo?.MobileImage?.data?.attributes?.alternativeText ?? 'MiniHero Slide'}
                    fill
                    style={{ objectFit: 'cover' }}
                    placeholder='blur'
                    blurDataURL={
                      promo?.MobileImage?.data?.attributes?.url ??
                      'https://via.placeholder.com/72x72/afd8e6/969696'
                    }
                  />
                </Link>
              </SwiperSlide>
            )
          })}
        </Swiper>
        {/* MiniHero Mobile Slider end */}
      </div>
    </section>
  )
}

export default MiniHero
