import { useRouter } from 'next/router'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { ICartPageModalProps, IModalContext, IMyOrderPadModalProps } from 'types/context'

import { useSideBarContext } from './sideBar-context'

const ModalContext = createContext({} as IModalContext)

export function ModalWrapper({ children, values }: any) {
  /** Authentication States */
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false)
  const [isForgotPasswordModalOpen, setIsForgotPasswordModalOpen] = useState(false)
  const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] = useState(false)
  const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false)
  const [isVerifyEmailModalOpen, setIsVerifyEmailModalOpen] = useState(false)

  /** My Account States */
  const [isMyAccountModalOpen, setIsMyAccountModalOpen] = useState(false)
  const [isLinkToCreditAccountModal, setIsLinkToCreditAccountModal] = useState(false)

  /** Coming Soon Modal States */
  const [isComingSoonModal, setIsComingSoonModal] = useState(false)

  /** Mega Menu Modal States */
  const [isMegaMenuModalOpen, setIsMegaMenuModalOpen] = useState(false)

  /** Main Menu Side Pane States */
  const [isMainMenuSidePaneOpen, setIsMainMenuSidePaneOpen] = useState(false)

  /** Search Result Context */
  const [searchResultDropdown, setSearchResultDropdown] = useState(false)
  const [searchInput, setSearchInput] = useState('')
  const [expandSearch, setExpandSearch] = useState(false)

  /** Block UI */
  const [isBlockUIOpen, setIsBlockUIOpen] = useState(false)

  /** Save Cart */
  const [isSaveCartModalOpen, setIsSaveCartModalOpen] = useState(false)
  const [cartPageModals, setCartPageModals] = useState<ICartPageModalProps>({
    isOpenRemoveModal: false,
    isOpenDiscardSavings: false,
    isOpenClearCartModal: false,
    isOpenExpectedDispatchDateModal: false
  })

  /** My Order-Pad */
  const [myOrderPadModals, setMyOrderPadModals] = useState<IMyOrderPadModalProps>({
    isOpenRemoveModal: false,
    isOpenDiscardSavings: false,
    isOpenSaveAsNewOrderPadModal: false,
    isOpenRemoveOrderPadModal: false,
    isOpenMergeConfirmationModal: false,
    isOpenSaveSuccessModal: false
  })

  /** Bulk order */
  const [isBulkOrderModalOpen, setIsBulkOrderModalOpen] = useState<boolean>(false)
  /** Expected delivery product card */
  const [isExpectedDispatchOpen, setIsExpectedDispatchOpen] = useState(false)
  const [expectedDispatchDetails, setExpectedDispatchDetails] = useState({
    hasVariant: false,
    sku: '',
    name: '',
    stock: 0,
    store: {
      count: 0
    },
    expectedDispatchDate: ''
  })

  /** Store location modal */
  const [isOpenStoreLocationModal, setIsOpenStoreLocationModal] = useState(false)
  const [storeLocationModalDetails, setStoreLocationModalDetails] = useState({
    sku: '',
    name: '',
    id: '',
    image: {
      id: '',
      url: '',
      alt: '',
      title: ''
    }
  })
  const [isOpenNoStockPickupModal, setIsOpenNoStockPickupModal] = useState(false)
  const [noStockPickUpModalDetails, setNoStockPickUpModalDetails] = useState({
    hasVariant: false,
    sku: '',
    name: '',
    stock: 0,
    store: {
      count: 0
    }
  })

  /** Change password modal */
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false)

  /** Search address modal */
  const [isSearchAddressModalOpen, setIsSearchAddressModalOpen] = useState(false)
  const [isAddressDetailsRequired, setIsAddressDetailsRequired] = useState(false)
  const [searchAddressModalDetails, setSearchAddressModalDetails] = useState({
    attentionTo: '',
    city: '',
    isDefaultAddress: false,
    postcode: '',
    searchingAddress: '',
    showAttentionTo: false,
    showMakeDefaultAddress: false,
    streetAddress: '',
    suburb: ''
  })
  const [searchAddressModalPrimaryBtnText, setSearchAddressModalPrimaryBtnText] =
    useState(undefined)
  const [searchAddressModalTitle, setSearchAddressModalTitle] = useState('')
  const [searchAddressModalSaveFn, setSearchAddressModalSaveFn] = useState(() => () => {})
  const [searchAddressModalBtnLoading, setSearchAddressModalBtnLoading] = useState(false)
  const [searchAddressUpdateAccountDetails, setSearchAddressUpdateAccountDetails] = useState(false)

  const router = useRouter()
  const { quickCartSidebar } = useSideBarContext()
  const { isOpen: isOpenQuickCartSideBar } = quickCartSidebar

  useEffect(() => {
    if (
      isForgotPasswordModalOpen ||
      isLinkToCreditAccountModal ||
      isComingSoonModal ||
      isMegaMenuModalOpen ||
      isMainMenuSidePaneOpen ||
      isLoginModalOpen ||
      isMyAccountModalOpen ||
      isResetPasswordModalOpen ||
      isSignUpModalOpen ||
      isVerifyEmailModalOpen ||
      isExpectedDispatchOpen ||
      isOpenNoStockPickupModal ||
      isOpenStoreLocationModal ||
      isBlockUIOpen ||
      isBulkOrderModalOpen ||
      isOpenQuickCartSideBar ||
      isSaveCartModalOpen ||
      isChangePasswordModalOpen ||
      isSearchAddressModalOpen ||
      cartPageModals.isOpenRemoveModal ||
      cartPageModals.isOpenDiscardSavings ||
      cartPageModals.isOpenClearCartModal ||
      cartPageModals.isOpenExpectedDispatchDateModal ||
      myOrderPadModals.isOpenSaveSuccessModal ||
      myOrderPadModals?.isOpenRemoveModal ||
      myOrderPadModals?.isOpenDiscardSavings ||
      myOrderPadModals?.isOpenMergeConfirmationModal ||
      myOrderPadModals?.isOpenSaveAsNewOrderPadModal ||
      myOrderPadModals?.isOpenRemoveOrderPadModal
    ) {
      if (typeof window != 'undefined' && window.document) {
        const scrollBarCompensation = window.innerWidth - document.body.offsetWidth
        document.body.style.overflow = 'hidden'
        document.body.style.paddingRight = `${scrollBarCompensation}px`
      }
    } else if (typeof window != 'undefined' && window.document) {
      document.body.style.overflow = ''
      document.body.style.paddingRight = ''
    }
  }, [
    isBlockUIOpen,
    isForgotPasswordModalOpen,
    isLinkToCreditAccountModal,
    isComingSoonModal,
    isMegaMenuModalOpen,
    isMainMenuSidePaneOpen,
    isLoginModalOpen,
    isMyAccountModalOpen,
    isResetPasswordModalOpen,
    isSignUpModalOpen,
    isVerifyEmailModalOpen,
    isBulkOrderModalOpen,
    isExpectedDispatchOpen,
    isOpenNoStockPickupModal,
    isOpenStoreLocationModal,
    isOpenQuickCartSideBar,
    isSaveCartModalOpen,
    isChangePasswordModalOpen,
    isSearchAddressModalOpen,
    cartPageModals,
    myOrderPadModals
  ])

  const sharedState: IModalContext = {
    allModals: {
      close: () => {
        setIsLoginModalOpen(false)
        setIsForgotPasswordModalOpen(false)
        setIsResetPasswordModalOpen(false)
        setIsSignUpModalOpen(false)
        setIsVerifyEmailModalOpen(false)
        setIsMyAccountModalOpen(false)
        setIsLinkToCreditAccountModal(false)
        setIsComingSoonModal(false)
        setIsMegaMenuModalOpen(false)
        setIsMainMenuSidePaneOpen(false)
        setIsSaveCartModalOpen(false)
        setIsExpectedDispatchOpen(false)
        setIsOpenStoreLocationModal(false)
        setIsOpenNoStockPickupModal(false)
        setIsBlockUIOpen(false)
        setIsBulkOrderModalOpen(false)
        setIsChangePasswordModalOpen(false)
        setIsSearchAddressModalOpen(false)
        setSearchAddressModalDetails({
          attentionTo: '',
          city: '',
          isDefaultAddress: false,
          postcode: '',
          searchingAddress: '',
          showAttentionTo: false,
          showMakeDefaultAddress: false,
          streetAddress: '',
          suburb: ''
        })
        setSearchAddressModalSaveFn(() => () => {})
        setSearchAddressModalBtnLoading(false)
        setSearchAddressUpdateAccountDetails(false)
        setCartPageModals(prevState => ({
          ...prevState,
          isOpenRemoveModal: false,
          isOpenDiscardSavings: false,
          isOpenClearCartModal: false,
          isOpenExpectedDispatchDateModal: false
        }))
      }
    },
    isAnyModalOpen: () => {
      return (
        isLoginModalOpen ||
        isForgotPasswordModalOpen ||
        isResetPasswordModalOpen ||
        isSignUpModalOpen ||
        isVerifyEmailModalOpen ||
        isMyAccountModalOpen ||
        isLinkToCreditAccountModal ||
        isComingSoonModal ||
        isMegaMenuModalOpen ||
        isMainMenuSidePaneOpen ||
        isExpectedDispatchOpen ||
        isOpenNoStockPickupModal ||
        isOpenStoreLocationModal ||
        isBlockUIOpen ||
        isBulkOrderModalOpen ||
        isOpenQuickCartSideBar ||
        isSaveCartModalOpen ||
        isChangePasswordModalOpen ||
        isSearchAddressModalOpen ||
        cartPageModals.isOpenRemoveModal ||
        cartPageModals.isOpenClearCartModal ||
        cartPageModals.isOpenExpectedDispatchDateModal
      )
    },
    loginModal: {
      isOpen: isLoginModalOpen,
      set: setIsLoginModalOpen,
      open: () => setIsLoginModalOpen(true),
      close: () => {
        const { isRedirect, ...restQuery } = router.query
        if (isRedirect) {
          router.push(
            {
              pathname: router.pathname,
              query: restQuery
            },
            undefined,
            { shallow: true }
          )
        }
        setIsLoginModalOpen(false)
      }
    },
    forgotPasswordModal: {
      isOpen: isForgotPasswordModalOpen,
      set: setIsForgotPasswordModalOpen,
      open: () => setIsForgotPasswordModalOpen(true),
      close: () => setIsForgotPasswordModalOpen(false)
    },
    resetPasswordModal: {
      isOpen: isResetPasswordModalOpen,
      set: setIsResetPasswordModalOpen,
      open: () => setIsResetPasswordModalOpen(true),
      close: () => setIsResetPasswordModalOpen(false)
    },
    signUpModal: {
      isOpen: isSignUpModalOpen,
      set: setIsSignUpModalOpen,
      open: () => setIsSignUpModalOpen(true),
      close: () => setIsSignUpModalOpen(false)
    },
    verifyEmailModal: {
      isOpen: isVerifyEmailModalOpen,
      set: setIsVerifyEmailModalOpen,
      open: () => setIsVerifyEmailModalOpen(true),
      close: () => setIsVerifyEmailModalOpen(false)
    },
    myAccountModal: {
      isOpen: isMyAccountModalOpen,
      set: setIsMyAccountModalOpen,
      open: () => setIsMyAccountModalOpen(true),
      close: () => setIsMyAccountModalOpen(false)
    },
    linkToCreditAccountModal: {
      isOpen: isLinkToCreditAccountModal,
      set: setIsLinkToCreditAccountModal,
      open: () => setIsLinkToCreditAccountModal(true),
      close: () => setIsLinkToCreditAccountModal(false)
    },
    comingSoonModal: {
      isOpen: isComingSoonModal,
      set: setIsComingSoonModal,
      open: () => setIsComingSoonModal(true),
      close: () => setIsComingSoonModal(false)
    },
    megaMenuModal: {
      isOpen: isMegaMenuModalOpen,
      set: setIsMegaMenuModalOpen,
      open: () => setIsMegaMenuModalOpen(true),
      close: () => setIsMegaMenuModalOpen(false)
    },
    mainMenuSidePane: {
      isOpen: isMainMenuSidePaneOpen,
      set: setIsMainMenuSidePaneOpen,
      open: () => setIsMainMenuSidePaneOpen(true),
      close: () => setIsMainMenuSidePaneOpen(false)
    },
    searchResultDropdown: {
      isOpen: searchResultDropdown,
      set: setSearchResultDropdown,
      search: setSearchInput,
      searchKeyword: searchInput,
      open: () => setSearchResultDropdown(true),
      close: () => setSearchResultDropdown(false)
    },
    expandSearch: {
      isOpen: expandSearch,
      set: setExpandSearch,
      open: () => setExpandSearch(true),
      close: () => setExpandSearch(false)
    },
    blockUI: {
      isOpen: isBlockUIOpen,
      set: setIsBlockUIOpen,
      open: () => setIsBlockUIOpen(true),
      close: () => setIsBlockUIOpen(false)
    },
    bulkOrderModal: {
      isOpen: isBulkOrderModalOpen,
      set: setIsBulkOrderModalOpen,
      open: () => setIsBulkOrderModalOpen(true),
      close: () => setIsBulkOrderModalOpen(false)
    },
    // Expected dispatch modal context
    expectedDispatchModal: {
      ...expectedDispatchDetails,
      isOpen: isExpectedDispatchOpen,
      open: () => setIsExpectedDispatchOpen(true),
      close: () => setIsExpectedDispatchOpen(false),
      set: setExpectedDispatchDetails
    },
    // Store location modal context
    pickUpModal: {
      ...storeLocationModalDetails,
      isOpen: isOpenStoreLocationModal,
      open: () => setIsOpenStoreLocationModal(true),
      close: () => {
        setIsOpenStoreLocationModal(false)
      },
      set: setStoreLocationModalDetails
    },
    noStockPickUpModal: {
      ...noStockPickUpModalDetails,
      isOpen: isOpenNoStockPickupModal,
      open: () => setIsOpenNoStockPickupModal(true),
      close: () => setIsOpenNoStockPickupModal(false),
      set: setNoStockPickUpModalDetails
    },
    saveCartModal: {
      isOpen: isSaveCartModalOpen,
      set: setIsSaveCartModalOpen,
      open: () => setIsSaveCartModalOpen(true),
      close: () => setIsSaveCartModalOpen(false)
    },
    changePasswordModal: {
      isOpen: isChangePasswordModalOpen,
      set: setIsChangePasswordModalOpen,
      open: () => setIsChangePasswordModalOpen(true),
      close: () => setIsChangePasswordModalOpen(false)
    },
    searchAddressModal: {
      ...searchAddressModalDetails,
      close: () => setIsSearchAddressModalOpen(false),
      isAddressDetailsRequired,
      isOpen: isSearchAddressModalOpen,
      isPrimaryBtnLoading: searchAddressModalBtnLoading,
      isUpdateAccountDetails: searchAddressUpdateAccountDetails,
      open: () => setIsSearchAddressModalOpen(true),
      primaryBtnText: searchAddressModalPrimaryBtnText,
      saveFn: searchAddressModalSaveFn,
      setAddressDetails: setSearchAddressModalDetails,
      setIsAddressDetailsRequired,
      setIsUpdateAccountDetails: setSearchAddressUpdateAccountDetails,
      setPrimaryBtnLoading: setSearchAddressModalBtnLoading,
      setPrimaryBtnText: setSearchAddressModalPrimaryBtnText,
      setSaveFn: setSearchAddressModalSaveFn,
      setTitle: setSearchAddressModalTitle,
      title: searchAddressModalTitle
    },
    cartPageModals: {
      ...cartPageModals,
      set: setCartPageModals,
      openRemoveModal: () => {
        setCartPageModals(prevState => ({ ...prevState, isOpenRemoveModal: true }))
      },
      closeRemoveModal: () => {
        setCartPageModals(prevState => ({ ...prevState, isOpenRemoveModal: false }))
      },
      openDiscardSavingsModal: () => {
        setCartPageModals(prevState => ({ ...prevState, isOpenDiscardSavings: true }))
      },
      closeDiscardSavingsModal: () => {
        setCartPageModals(prevState => ({ ...prevState, isOpenDiscardSavings: false }))
      },
      openClearCartModal: () => {
        setCartPageModals(prevState => ({ ...prevState, isOpenClearCartModal: true }))
      },
      closeClearCartModal: () => {
        setCartPageModals(prevState => ({ ...prevState, isOpenClearCartModal: false }))
      },
      openExpectedDispatchDateModal: () => {
        setCartPageModals(prevState => ({ ...prevState, isOpenExpectedDispatchDateModal: true }))
      },
      closeExpectedDispatchDateModal: () => {
        setCartPageModals(prevState => ({ ...prevState, isOpenExpectedDispatchDateModal: false }))
      }
    },
    myOrderPadModals: {
      ...myOrderPadModals,
      set: setMyOrderPadModals,
      openRemoveModal: () => {
        setMyOrderPadModals(prevState => ({ ...prevState, isOpenRemoveModal: true }))
      },
      closeRemoveModal: () => {
        setMyOrderPadModals(prevState => ({ ...prevState, isOpenRemoveModal: false }))
      },
      openDiscardSavingsModal: () => {
        setMyOrderPadModals(prevState => ({ ...prevState, isOpenDiscardSavings: true }))
      },
      closeDiscardSavingsModal: () => {
        setMyOrderPadModals(prevState => ({ ...prevState, isOpenDiscardSavings: false }))
      },
      openSaveAsNewOrderPadModal: () => {
        setMyOrderPadModals(prevState => ({ ...prevState, isOpenSaveAsNewOrderPadModal: true }))
      },
      closeSaveAsNewOrderPadModal: () => {
        setMyOrderPadModals(prevState => ({ ...prevState, isOpenSaveAsNewOrderPadModal: false }))
      },
      openRemoveOrderPadModal: () => {
        setMyOrderPadModals(prevState => ({ ...prevState, isOpenRemoveOrderPadModal: true }))
      },
      closeRemoveOrderPadModal: () => {
        setMyOrderPadModals(prevState => ({ ...prevState, isOpenRemoveOrderPadModal: false }))
      },
      openMergeConfirmationModal: () => {
        setMyOrderPadModals(prevState => ({ ...prevState, isOpenMergeConfirmationModal: true }))
      },
      closeMergeConfirmationModal: () => {
        setMyOrderPadModals(prevState => ({ ...prevState, isOpenMergeConfirmationModal: false }))
      },
      openSaveSuccessModal: () => {
        setMyOrderPadModals(prevState => ({ ...prevState, isOpenSaveSuccessModal: true }))
      },
      closeSaveSuccessModal: () => {
        setMyOrderPadModals(prevState => ({ ...prevState, isOpenSaveSuccessModal: false }))
      }
    }
  }

  useEffect(() => {
    if (router?.pathname !== '/search' && sharedState.expandSearch.isOpen) {
      sharedState.expandSearch.close()
    }
  }, [router?.pathname])

  // Closing all modal on back button
  useEffect(() => {
    const handleBackButton = () => {
      sharedState.allModals.close()
    }

    // execute when back button clicks
    window.addEventListener('popstate', handleBackButton)

    return () => {
      window.removeEventListener('popstate', handleBackButton)
    }
  }, [])

  return (
    <ModalContext.Provider value={{ ...sharedState, ...values }}>{children}</ModalContext.Provider>
  )
}

export function useModalContext() {
  return useContext(ModalContext)
}
