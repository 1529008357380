import crypto from 'crypto'

const algorithm = process.env.NEXT_PUBLIC_CRYPTO_ALGORITHM as crypto.CipherGCMTypes
const key = process.env.NEXT_PUBLIC_CRYPTO_KEY as string
const iv = process.env.NEXT_PUBLIC_CRYPTO_IV as string

export const decrypt = (text: string) => {
  if (text && text?.length > 0) {
    const encryptedText = Buffer.from(text, 'hex')
    const decipher = crypto.createDecipheriv(algorithm, Buffer.from(key), iv)
    let decrypted = decipher.update(encryptedText)
    decrypted = Buffer.concat([decrypted, decipher.final()])
    return decrypted.toString()
  }
}

export default decrypt
