import { useMemo } from 'react'

export const DOTS = '...'

const range = (start: number, end: number) => {
  const length = end - start + 1
  /*
        Create an array of certain length and set the elements within it from
        start value to end value.
    */
  return Array.from({ length }, (_, idx) => idx + start)
}

export const usePagination = ({
  totalPages,
  resultsCount,
  pageSizes,
  siblingCount,
  pageIndex
}: any) => {
  const paginationRange = useMemo(() => {
    // const totlePageCount = Math.ceil(resultsCount / pageSizes)

    //pages count is determined as siblingCount + firstPage + lastPage + pageIndex + 2*DOTS
    const totalPageNumbers = siblingCount + 6

    /*
        Case 1:
        if the number of pages is less than the page numbers we want to show in our paginationComponent
        we return the range [1..totalPageCount]
    */
    if (totalPageNumbers >= totalPages) {
      return range(1, totalPages)
    }

    /*
        Calculate left and right sibling index and make sure they are within range 1 and totlePageCount
    */
    const leftSiblingIndex = Math.max(pageIndex - siblingCount, 1)
    const rightSiblingIndex = Math.min(pageIndex + siblingCount, totalPages)

    /*
        We do not want to show dots if there is only one position left
    */
    const shouldShowLeftDots = leftSiblingIndex > 3
    const shouldShowRightDots = rightSiblingIndex < totalPages - 2

    const firstPageIndex = 1
    const lastPageIndex = totalPages

    /*
        Case 2: No left dots to show, but rights dots to show
        Calculate the right most sibling index based on firstPageIndex and siblingCount
    */
    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = 3 + 2 * siblingCount
      const leftRange = range(1, leftItemCount)

      return [...leftRange, DOTS, totalPages]
    }

    /*
        Case 3: No right dots to show, but left dots to show
        Calculate the left most sibling index based on lastPageIndex and siblingCount
    */
    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = 3 + 2 * siblingCount
      const rightRange = range(totalPages - rightItemCount + 1, totalPages)

      return [firstPageIndex, DOTS, ...rightRange]
    }

    /*
        Case 4: Both left and right dots to show
        Calculate the right most sibling index based on firstPageIndex and siblingCount
        Calculate the left most sibling index based on lastPageIndex and siblingCount
    */
    if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = range(leftSiblingIndex, rightSiblingIndex)

      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex]
    }
    return null
  }, [totalPages, resultsCount, pageSizes, siblingCount, pageIndex])

  return paginationRange
}
