import { useCallback } from 'react'
import { AlertProps, Toast } from '@nzsb/shopnx-ui'
import { useQuery } from '@tanstack/react-query'
import { useAppContext } from 'lib/contexts/app-context'
import { httpGET } from 'lib/http'

export interface IGetCartResponse {
  messageTitle: string | null
  messageDescription: string | null
  messageType: string
  data: IGetCart
}

export interface IGetCart {
  cartId: string
  noOfItems: number
  validationMessage?: {
    type: number
    validationCriteria: number
    messageType: string
    title: string
    message: string
    productId: number
    variantId: number
  } | null
}

export const getCart = async (context?: any) => {
  const data = await httpGET({
    url: `/cart/getCart`,
    type: 'internal',
    endpoint: 'api',
    context
  })
  return data as IGetCartResponse
}

export const useGetCart = () => {
  const { cart } = useAppContext()
  const notify = (props: AlertProps) => Toast(props)

  const updateQuickCartContext = useCallback((quickCartInfo: IGetCart) => {
    cart?.setQuickCartIconInfo(quickCartInfo)
  }, [])

  return useQuery({
    queryKey: ['GET_CART'],
    queryFn: async (context: any) => {
      const quickCart = await getCart(context)
      updateQuickCartContext({ ...quickCart?.data })
      if (quickCart?.data?.validationMessage) {
        notify({
          status: 'Warning',
          alertHeader: quickCart?.data?.validationMessage?.title,
          alertBody: quickCart?.data?.validationMessage?.message
        })
      }
      return quickCart
    },
    enabled: false,
    refetchOnWindowFocus: false
  })
}
