import { useMutation } from '@tanstack/react-query'
import { httpPOST } from 'lib/http'

export interface ISendStatementResponse {
  data: string
  messageDescription: string
  messageTitle: string
  messageType: string
}

export interface ISendInvoiceRequest {
  cCtoMySelf: boolean
  cCtoNZTeam: boolean
  message: string
  statementList?: string[]
  subject: string
  toEmail: string
}

export const sendStatementEmail = async (req: ISendInvoiceRequest) => {
  const { data } = await httpPOST({
    url: `statement/send_email_statement`,
    body: req
  })
  return data as ISendStatementResponse
}

export const useSendStatementEmail = () => {
  return useMutation({
    mutationFn: sendStatementEmail
  })
}
