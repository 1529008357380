import Link from 'next/link'
import React, { FC } from 'react'
import CN from 'classnames'

export interface LinksProps {
  id: number
  label: string
  href?: string
  target?: string
  hidden?: boolean
  onClick?: () => void
}

export interface NavBlockProps {
  [x: string]: any
  className?: string
  heading?: string
  navList?: LinksProps[]
  navId?: number
}

export const NavBlock: FC<NavBlockProps> = ({
  className,
  heading,
  navList,
  navId,
  ...restProps
}: NavBlockProps) => {
  const NavBlockClasses = CN(`nav-block`, className)

  return (
    <div data-component-id='nav-block' className={NavBlockClasses} {...restProps}>
      {heading && <p className='text-base text-B-25 mb-[8px] font-bold'>{heading}</p>}
      {navList && navList?.length > 0 && (
        <nav className='flex flex-col gap-y-[8px]'>
          {navList &&
            navList?.length > 0 &&
            navList.map((nav: LinksProps) => (
              <Link
                href={nav?.href ?? '#'}
                target={nav?.target ?? '_self'}
                key={nav?.id}
                className={CN('text-sm text-B-200 hover:text-white', {
                  // TODO: Remove this once the promotions page is ready
                  'md:hidden': navId === 1 && nav.label === 'Current Promotions',
                  'cursor-pointer': nav?.href,
                  'hidden': !nav?.href && !nav?.onClick
                })}
                hidden={nav?.hidden}
                onClick={() => {
                  if (nav?.onClick) {
                    nav?.onClick()
                  }
                }}>
                {nav?.label}
              </Link>
            ))}
        </nav>
      )}
    </div>
  )
}

NavBlock.defaultProps = {
  className: undefined,
  heading: undefined,
  navList: undefined
}

export default NavBlock
