import { useRouter } from 'next/router'
import React, { FC } from 'react'
import CN from 'classnames'

import { Breadcrumb } from 'components/molecules'

export interface IStoreLocatorPageHeaderProps {
  className?: string
  componentId?: string
  description?: string
  title?: string
  location?: string
}

export const StoreLocatorPageHeader: FC<IStoreLocatorPageHeaderProps> = ({
  className,
  componentId,
  location,
  description,
  title,
  ...restProps
}: IStoreLocatorPageHeaderProps) => {
  const StoreLocatorPageHeaderClasses = CN(
    `${componentId}-page-header section container w-full`,
    className
  )

  const router = useRouter()

  const breadcrumItems = [
    { id: 0, label: 'Home', onClick: () => router.push('/') },
    { id: 1, label: 'Store Locator', slug: '/store-locator' }
  ]

  const newBreadcrumb = location
    ? { id: 2, label: location, slug: `/store-locator/${location}` }
    : null

  const updatedBreadcrumItems = newBreadcrumb ? [...breadcrumItems, newBreadcrumb] : breadcrumItems

  return (
    <div className={StoreLocatorPageHeaderClasses} {...restProps}>
      <section className='flex flex-col pt-[24px] gap-[24px]' {...restProps}>
        <div className='flex flex-col gap-1'>
          {<Breadcrumb data={updatedBreadcrumItems} />}
          <span
            data-component-id={`${componentId}-title`}
            className='font-normal text-display-h1-m lg:text-display-h1 font-display text-N-800'>
            {title}
          </span>

          {description && (
            <span
              data-component-id={`${componentId}-description`}
              className='pt-1 mb-6 text-base font-400 text-N-700'>
              {description}
            </span>
          )}
        </div>
      </section>
    </div>
  )
}

export default StoreLocatorPageHeader
