/* eslint-disable @typescript-eslint/no-unused-expressions */
import Link from 'next/link'
import React, { FC, useCallback, useEffect, useState } from 'react'
import {
  Alert,
  Button,
  CheckBox,
  debounce,
  RegexType,
  TextField,
  useReCaptcha,
  validateRegex
} from '@nzsb/shopnx-ui'
import CN from 'classnames'
import { Form, Formik } from 'formik'
import { AnimatePresence, motion } from 'framer-motion'
import { useEmailVerification, useSignUp } from 'lib/actions'
import { useResendActivationLink } from 'lib/actions/auth'
import { useModalContext } from 'lib/contexts/modal-context'
import { SignUpSchema } from 'lib/schemas/signUp'

import { PasswordInput, PhoneInput } from 'components/atoms'
import { OnScreenMessage } from 'components/molecules'

export interface ISignUpFormProps {
  className?: string
  setShowResult?: any
  hideHeader?: boolean
}

export interface IFormValues {
  firstName: string
  lastName: string
  emailAddress: string
  mobileNumber: string
  companyName: string
  password: string
  isSubscribed: boolean
}

export const SignUpForm: FC<ISignUpFormProps> = ({
  className,
  setShowResult,
  hideHeader,
  ...restProps
}: ISignUpFormProps) => {
  const { generateToken } = useReCaptcha()

  const { signUpModal } = useModalContext()

  const [isEmailAlreadyExist, setIsEmailAlreadyExist] = useState<boolean>(false)
  const [isEmailAlreadyExistNotActivated, setIsEmailAlreadyExistNotActivated] =
    useState<boolean>(false)
  const [emailHintMessage, setEmailHintMessage] = useState<string>('')
  const [showPasswordHint, setShowPasswordHint] = useState(false)

  const { emailVerification, isPending: isLoadingEmailVerification } = useEmailVerification()

  /* ClientSide: Handle email verification */
  const verifyEmailAddress = async (emailAddress: string) => {
    if (!validateRegex(emailAddress, RegexType.EMAIL)) {
      setEmailHintMessage('Invalid email')
      return false
    } else {
      const response = await emailVerification(emailAddress)
      if (response?.data?.messageType === 'success') {
        setEmailHintMessage('')
        setIsEmailAlreadyExist(false)
        setIsEmailAlreadyExistNotActivated(false)
      } else if (response?.data?.messageType === 'error') {
        setEmailHintMessage('It appears that you already have an account with this email.')
        setIsEmailAlreadyExist(true)
        setIsEmailAlreadyExistNotActivated(false)
      } else if (response?.data?.messageType === 'warning') {
        setEmailHintMessage('It appears that you already have an account with this email.')
        setIsEmailAlreadyExistNotActivated(true)
        setIsEmailAlreadyExist(false)
      } else {
        setEmailHintMessage('Oops! Something went wrong. Please try again later.')
        setIsEmailAlreadyExist(false)
        setIsEmailAlreadyExistNotActivated(false)
      }
      return response
    }
  }

  const optimizedFunction = useCallback(
    debounce((e: any) => {
      verifyEmailAddress(e)
    }, 500),
    []
  )

  const { signUp, isPending: signUpLoading, data: signUpData } = useSignUp()

  /* Handle form submit */
  const handleSignUp = async (values: IFormValues) => {
    /* Generate G-captcha token */
    const token = await generateToken()

    /* Trigger Sign Up mutate hook for the API call */
    await signUp({
      ...values,
      captchaToken: token
    })
  }

  useEffect(() => {
    setShowResult?.(signUpData || false)
  }, [signUpData])

  const togglePasswordHint = () => {
    setShowPasswordHint(!showPasswordHint)
  }

  const [resendLinkActivationStatus, setResendLinkActivationStatus] = useState(false)

  const { resendActivationLinkAsync, data: resendActivationLinkData } = useResendActivationLink()

  const resendActivationLink = async (emailAddress: string) => {
    const response = await resendActivationLinkAsync({
      emailAddress
    })
    if (response?.messageType === 'success') {
      setShowResult?.(true)
      setIsEmailAlreadyExist(false)
      setIsEmailAlreadyExistNotActivated(false)
      setEmailHintMessage('')
      setResendLinkActivationStatus(true)
    }
  }

  const iconAfter = (value: string, isExist: boolean, isNotActivated: boolean) => {
    if (!validateRegex(value, RegexType.EMAIL)) {
      return undefined
    }

    if (isExist || isNotActivated) {
      return 'nzsbi-exclamation-1'
    }

    return 'nzsbi-check-1'
  }
  const SignUpClasses = CN(`shopnx-app justify-center items-start`, className)
  return (
    <div className={SignUpClasses} {...restProps}>
      {!resendLinkActivationStatus && !signUpData && (
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            mobileNumber: '',
            companyName: '',
            password: '',
            isSubscribed: false,
            emailAddress: ''
          }}
          onSubmit={handleSignUp}
          validationSchema={SignUpSchema}>
          {({
            errors,
            touched,
            values,
            handleChange,
            handleBlur,
            setFieldTouched,
            setFieldValue,
            handleSubmit,
            isSubmitting
          }) => (
            <Form>
              <div className='flex flex-col bg-white px-8 pb-8'>
                {!hideHeader && (
                  <p className='text-N-700 md:text-display-h2 text-display-h2-m font-display pb-10 uppercase'>
                    CREATE YOUR ONLINE ACCOUNT
                  </p>
                )}

                <div className='flex flex-col md:flex-row gap-y-5 gap-x-8'>
                  <TextField
                    value={values?.firstName}
                    label='First Name'
                    required
                    wrapperClassName='w-full'
                    onChange={handleChange('firstName')}
                    onBlur={handleBlur('firstName')}
                    hasError={!!(errors.firstName && touched.firstName)}
                    hint={errors.firstName && touched.firstName ? errors.firstName : ''}
                    componentId='firstName'
                    id='firstName'
                  />

                  <TextField
                    value={values?.lastName}
                    label='Last Name'
                    required
                    wrapperClassName='w-full lg:mt-0'
                    onChange={handleChange('lastName')}
                    onBlur={handleBlur('lastName')}
                    hasError={!!(errors.lastName && touched.lastName)}
                    hint={errors.lastName && touched.lastName ? errors.lastName : ''}
                    componentId='lastName'
                    id='lastName'
                  />
                </div>

                <div className='relative mt-5'>
                  <TextField
                    componentId='emailAddress'
                    id='emailAddress'
                    value={values?.emailAddress}
                    label='Email Address'
                    required
                    wrapperClassName='w-full'
                    iconAfter={iconAfter(
                      values?.emailAddress,
                      isEmailAlreadyExist,
                      isEmailAlreadyExistNotActivated
                    )}
                    style={{ paddingRight: '40px' }}
                    type='email'
                    onChange={(e: any) => {
                      setFieldValue('emailAddress', e.target.value)
                      setFieldTouched('emailAddress', true)
                      optimizedFunction(e.target.value)
                    }}
                    onBlur={(e: any) => {
                      setFieldValue('emailAddress', e.target.value)
                      setFieldTouched('emailAddress', true)
                      optimizedFunction(e.target.value)
                    }}
                    hasError={
                      (emailHintMessage === 'Invalid email' ||
                        isEmailAlreadyExist ||
                        isEmailAlreadyExistNotActivated) &&
                      touched?.emailAddress
                    }
                    hint={emailHintMessage}
                  />

                  {isEmailAlreadyExist && (
                    <div className='text-R-600 text-xs mt-5'>
                      To access the account please{' '}
                      <span className='underline'>
                        <Link
                          href='/auth/login'
                          data-component-id='sign-up-form-sign-in-link'
                          onClick={signUpModal.close}
                          className='underline'>
                          sign in
                        </Link>
                      </span>{' '}
                      or if you can’t remember your password you can{' '}
                      <span className='underline'>
                        <Link
                          data-component-id='sign-up-form-forgot-password-link'
                          href='/auth/forgotPassword'
                          onClick={signUpModal.close}
                          className='underline'>
                          reset your password here
                        </Link>
                      </span>{' '}
                    </div>
                  )}

                  {isEmailAlreadyExistNotActivated && (
                    <div className='text-R-600 text-xs mt-5'>
                      <span>
                        Your online account is not activated.{' '}
                        <button
                          tabIndex={0}
                          data-component-id='sign-up-form-resend-activation-link'
                          onClick={() => {
                            resendActivationLink(values?.emailAddress)
                          }}
                          onKeyDown={e => {
                            if (e.key === 'Enter') {
                              resendActivationLink(values?.emailAddress)
                            }
                          }}
                          className='underline pl-[2px] cursor-pointer font-500'>
                          Resend Activation Link
                        </button>
                      </span>
                    </div>
                  )}
                </div>

                <PhoneInput
                  componentId='mobileNumber'
                  id='mobileNumber'
                  value={values?.mobileNumber}
                  className='mt-5'
                  label='Mobile Phone Number'
                  onChange={handleChange('mobileNumber')}
                  onBlur={handleBlur('mobileNumber')}
                  hasError={!!(errors.mobileNumber && touched.mobileNumber)}
                  hint={errors.mobileNumber && touched.mobileNumber ? errors.mobileNumber : ''}
                />

                <TextField
                  id='companyName'
                  componentId='companyName'
                  value={values?.companyName}
                  label='Business Name (if applicable)'
                  wrapperClassName='mt-5'
                  onChange={handleChange('companyName')}
                  onBlur={handleBlur('companyName')}
                  hasError={!!(errors.companyName && touched.companyName)}
                  hint={errors.companyName && touched.companyName ? errors.companyName : ''}
                />

                <PasswordInput
                  id='password'
                  componentId='password'
                  isStrengthBarEnable
                  value={values?.password}
                  label='Create Your Password'
                  required
                  className='mt-5'
                  onChange={handleChange('password')}
                  onBlur={handleBlur('password')}
                  hasError={!!(errors.password && touched.password)}
                  hint={errors.password && touched.password ? errors.password : ''}
                />

                <div>
                  <Button
                    componentId='passwordHint'
                    appearance='link-gray'
                    onClick={togglePasswordHint}
                    className='mt-[8px]'>
                    Password Hint
                  </Button>
                </div>

                <AnimatePresence>
                  {showPasswordHint && (
                    <motion.div
                      className='w-full'
                      initial={{ height: 0 }}
                      animate={{
                        height: 'auto'
                      }}
                      exit={{ height: 0, opacity: -2 }}>
                      <Alert
                        className='mt-4'
                        alertHeader='For a secure password'
                        alertBody='Please ensure your password contains a minimum of 8 characters consisting of at least one alpha character, uppercase character, numeric value and special character'
                        status='Info'
                        closeToast={togglePasswordHint}
                      />
                    </motion.div>
                  )}
                </AnimatePresence>

                <CheckBox
                  componentId='isSubscribed'
                  label=' I consent to receiving updates and promotional material from NZ Safety
                    Blackwoods'
                  id='isSubscribed'
                  className='mt-5 text-[14px]'
                  onChange={handleChange('isSubscribed')}
                  checked={values.isSubscribed || false}
                  labelSize='sm'
                />

                <div className='w-full text-xs text-N-700 mt-5'>
                  By creating an online account, you agree to the NZ Safety Blackwoods
                  <Link
                    href='/terms/terms-and-conditions'
                    target='_blank'
                    className='text-N-700 hover:text-O-500 hover:underline ml-1 underline'>
                    Terms and Conditions
                  </Link>
                </div>

                <Button
                  componentId='createOnlineAccount'
                  appearance='primary-orange'
                  isBlock
                  size='lg'
                  className='mt-3'
                  isLoading={signUpLoading}
                  onClick={handleSubmit}
                  disabled={
                    isLoadingEmailVerification ||
                    isSubmitting ||
                    isEmailAlreadyExist ||
                    isEmailAlreadyExistNotActivated ||
                    !!(
                      errors.firstName ||
                      errors.lastName ||
                      emailHintMessage ||
                      !values?.emailAddress ||
                      errors.emailAddress ||
                      errors.mobileNumber ||
                      errors.companyName ||
                      errors.password
                    )
                  }>
                  Create online Account
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      )}

      {!resendLinkActivationStatus && signUpData && (
        <OnScreenMessage
          className='px-[32px] pb-[32px] max-w-[480px]'
          header={signUpData?.messageType === 'success' ? 'Success' : 'Attention!'}
          type={signUpData?.messageType}
          subHeader={signUpData?.messageTitle}
          description={signUpData?.messageDescription}
        />
      )}

      {resendLinkActivationStatus && (
        <div className='px-8 pb-8 max-w-[480px]'>
          <OnScreenMessage
            className=''
            header={resendActivationLinkData.messageType === 'success' ? 'Success' : 'Attention!'}
            type={resendActivationLinkData.messageType}
            subHeader={resendActivationLinkData.messageTitle}
            description={resendActivationLinkData.messageDescription}
          />
        </div>
      )}
    </div>
  )
}

export default SignUpForm
