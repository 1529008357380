import { useQuery } from '@tanstack/react-query'
import { httpPOST } from 'lib/http'

import { InvoiceStatus, InvoiceTypes } from 'components/molecules'

export interface IInvoicesListReq {
  date?: {
    startDate: string
    endDate: string
  }
  type?: InvoiceTypes[]
  amount?: {
    start: number
    end: number
  } | null
  statuses?: InvoiceStatus[]

  searchFilter?: {
    field: string
    value: string
  } | null
  sortFilter?: {
    field: string
    type: string
  }
  pageIndex: number
  pageSize: number
}

export interface IInvoice {
  balance: number
  balanceWithGST: number
  customerPOReference: string
  date: string
  invoiceNumber: string
  isProcessed: boolean
  pdfUrl: string
  status: InvoiceStatus
  total: number
  totalWithGST: number
  type: InvoiceTypes
  userEmail: string
}

export interface IInvoicesListData {
  invoiceList: Array<IInvoice>
  pageIndex: number
  pageSize: number
  resultCount: number
  totalPages: number
}

export interface IInvoicesListRes {
  data: IInvoicesListData
  messageDescription: null | string
  messageTitle: string
  messageType: string
}

/**
 * Fetch order history
 * @param req Request object for fetching order history
 * @param context This is required for SSR only, otherwise the token is not attached
 * @returns IInvoicesListRes
 */
export const fetchInvoices = async (req: IInvoicesListReq, context?: any) => {
  const { data } = await httpPOST({
    url: '/invoice/get_invoice_list',
    body: req,
    context
  })

  return data?.data as IInvoicesListData
}

export const useGetInvoices = (req: IInvoicesListReq, initialData: IInvoicesListData) => {
  /* Use Query */
  return useQuery({
    queryKey: ['GET_INVOICES_LIST', req],
    queryFn: () => fetchInvoices(req),
    placeholderData: previousData => previousData ?? initialData
  })
}

export default useGetInvoices
