import React, { FC, useEffect, useMemo } from 'react'
import {
  Badge,
  Button,
  ColorBlock,
  HeaderProductCard,
  Lozenge,
  LozengeProps,
  priceFormat,
  QuantityPicker,
  Spinner
} from '@nzsb/shopnx-ui'
import CN from 'classnames'
import { useGetProductVariants } from 'lib/actions/products/getProductVariants'
import { useAppContext } from 'lib/contexts/app-context'
import { useModalContext } from 'lib/contexts/modal-context'
import { flagTypeMap } from 'lib/static/productStaticData'
import { checkDateDiff, checkRoleBasedProperties, convertDate } from 'lib/utilities'

import { NextImage } from 'components/atoms'
import { DataTable } from 'components/molecules/DataTable'

export interface IProductVariantsProps {
  className?: string
}

const CodeCell = ({ row }: any) => {
  const productFlag = row.original.variantFlag
  return (
    <div className='flex gap-[6px] flex-col'>
      <span className='flex gap-[12px]'>
        {row.original.sku}
        {row.original.isCatalogue && (
          <Lozenge appearance='subtle' hasClickEffect icon='nzsbi-catalogue' onClick={() => {}} />
        )}
      </span>
      {productFlag > 0 && (
        <Lozenge
          className={CN('uppercase font-700')}
          variant={flagTypeMap[productFlag].variant as LozengeProps['variant']}
          appearance={flagTypeMap[productFlag].appearance as LozengeProps['appearance']}>
          {flagTypeMap[productFlag].displayValue}
        </Lozenge>
      )}
    </div>
  )
}

const ColorCell = ({ row }: any) => {
  return (
    <div className='flex'>
      <ColorBlock
        colorList={row.original.colorValues}
        description={row.original.colorDescription}
      />
    </div>
  )
}

export const ProductVariants: FC<IProductVariantsProps> = ({
  className,
  ...restProps
}: IProductVariantsProps) => {
  const ProductVariantsClasses = CN(
    `product-variants overflow-y-auto hidden w-full 
  lg:!flex
  `,
    className
  )
  const { bulkOrderModal, expectedDispatchModal, pickUpModal, noStockPickUpModal } =
    useModalContext()
  const { productVariants, user, cart } = useAppContext()

  const { open: openDispatchModal, set: setDispatchModalDetails } = expectedDispatchModal
  const { open: openPickupModal, set: setPickupModalDetails } = pickUpModal
  const { open: openNoStockPickupModal, set: setNoStockPickupModalDetails } = noStockPickUpModal
  const roles = user?.data?.roles || []

  const { getVariants, isPending } = useGetProductVariants()

  const handleCellEdit = (rowIndex: any, value: number, data: any) => {
    // Update the other cell based on the edited cell

    const updatedData: any = data.map((row: any, index: any) => {
      if (index === rowIndex) {
        return {
          ...row,
          // [columnId]: value,
          // Modify other cell based on the edited cell
          cartQty: value, // Example: Increment the value of another cell
          isClearBtnVisible: value > 0
        }
      }
      return row
    })
    productVariants.setTableData(updatedData)
  }

  const handleClear = (rowIndex: any, data: any) => {
    // Update the other cell based on the edited cell

    const updatedData: any = data.map((row: any, index: any) => {
      if (index === rowIndex) {
        return {
          ...row,
          // [columnId]: value,
          // Modify other cell based on the edited cell
          cartQty: 0, // Example: Increment the value of another cell
          stock: row.stock,
          isClearBtnVisible: false
        }
      }
      return row
    })
    productVariants.setTableData(updatedData)
  }

  const getProdVariants = async () => {
    const data = await getVariants({
      id: productVariants.product?.id,
      SessionId: '',
      cartId: cart?.cartId
    })

    //set cart Quantity to 0
    const preppedData = data?.data?.map((row: any) => {
      row.cartQty = 0
      row.isClearBtnVisible = false
      return row
    })

    // Set the default quantity to 1 if only one variant is available
    if (data?.data?.length === 1) {
      handleCellEdit(0, 1, preppedData)
    } else {
      productVariants.setTableData(preppedData)
    }

    productVariants.setPrevTableData(preppedData)
  }

  useMemo(() => {
    if (bulkOrderModal.isOpen) getProdVariants()
    // Ensure useMemo returns something, even if it's null
    return null
  }, [bulkOrderModal.isOpen])

  useEffect(() => {
    if (productVariants.tableData?.length > 0) {
      const selectedRows = productVariants.tableData.filter((row: any) => {
        return row.cartQty > 0
      })
      productVariants.setSelectedProducts(selectedRows)
    }
  }, [productVariants.tableData])

  useEffect(() => {
    return () => {
      productVariants.setTableData([])
      productVariants.setSelectedProducts([])
    }
  }, [])

  const COLUMNS = useMemo(
    () => [
      { Header: 'Size', accessor: 'sizeCode', cellHeaderClassName: '!max-w-[100px]' },
      {
        Header: 'Code',
        accessor: 'sku',
        cellHeaderClassName: '!max-w-[230px]',
        Cell: CodeCell
      },
      {
        Header: 'Colour',
        accessor: 'colorDescription',
        cellHeaderClassName: '!max-w-[222px]',
        Cell: ColorCell
      },
      {
        Header: !checkRoleBasedProperties(roles)?.showGstPricePrimary
          ? 'Price (Excl. GST)'
          : 'Price (Incl. GST)',
        accessor: !checkRoleBasedProperties(roles)?.showGstPricePrimary ? 'price' : 'priceWithGst',
        cellHeaderClassName: '!max-w-[124px]',
        Cell: ({ row }: any) => {
          if (!checkRoleBasedProperties(roles)?.showGstPricePrimary)
            return (
              <span className='text-B-500'>
                ${Number(priceFormat(row.original.price))?.toFixed(2)}
              </span>
            )
          return (
            <span className='text-B-500'>
              ${Number(priceFormat(row.original.priceWithGst))?.toFixed(2)}
            </span>
          )
        }
      },
      {
        Header: 'Availability',
        accessor: 'availability',
        cellHeaderClassName: '!max-w-[286px]',
        Cell: ({ row }: any) => {
          return (
            <div className='flex flex-col gap-[4px] items-start'>
              {/* Availability Lozenge */}
              <button
                className={CN(`flex items-center justify-center gap-[8px] cursor-pointer`)}
                onKeyDown={() => {}}
                onClick={(e: any) => {
                  e.preventDefault()
                  openDispatchModal()
                  setDispatchModalDetails({
                    sku: row.original.sku,
                    id: productVariants?.product?.id,
                    store: {
                      count: row.original.pickUpStores
                    },
                    name: productVariants?.product?.title,
                    expectedDispatchDate: row.original?.expectedDeliveryDate,
                    stock: row.original?.stock,
                    size: row.original?.sizeCode,
                    color: row.original?.colorDescription
                  })
                }}>
                <Lozenge
                  icon='nzsbi-truck'
                  variant={row.original?.stock > 0 ? 'success' : 'warning-2'}
                  appearance='subtle'
                  className='px-[4px] cursor-pointer'></Lozenge>
                {row?.original?.stock > 0 && (
                  <span className='uppercase'>
                    {`Order Now - ${row?.original?.stock} ${
                      row?.original?.stock === 1 ? 'unit' : 'units'
                    } availables`}
                  </span>
                )}

                {row?.original?.stock === 0 && (
                  <span className='uppercase'>
                    {`EXPECTED DESPATCH - `}
                    {checkDateDiff(row?.original?.expectedDeliveryDate, 30)
                      ? `30+ Days`
                      : convertDate(row?.original?.expectedDeliveryDate)}
                  </span>
                )}
              </button>
              {/* Stores Lozenge */}
              <button
                className={CN(`flex items-center justify-center gap-[8px] cursor-pointer`)}
                onKeyDown={() => {}}
                onClick={(e: any) => {
                  e.preventDefault()
                  if (row.original.pickUpStores > 0) {
                    openPickupModal()
                    setPickupModalDetails({
                      sku: row.original?.sku,
                      id: row.original?.id,
                      store: {
                        count: row.original?.pickUpStores
                      },
                      name: productVariants.product?.title,
                      expectedDispatchDate: convertDate(row.original?.expectedDeliveryDate),
                      stock: row.original?.stock,
                      image: productVariants.product?.image
                    })
                  } else {
                    openNoStockPickupModal()
                    setNoStockPickupModalDetails({
                      sku: row.original?.sku,
                      store: {
                        count: row.original?.pickUpStores
                      },
                      name: productVariants.product?.title,
                      size: row.original?.sizeCode,
                      color: row.original?.colorDescription,
                      expectedDispatchDate: convertDate(row.original?.expectedDeliveryDate),
                      stock: row.original?.stock,
                      image: productVariants.product?.image
                    })
                  }
                }}>
                <Lozenge
                  icon='nzsbi-location-pin'
                  variant={row.original?.pickUpStores > 0 ? 'success' : 'disabled'}
                  appearance='subtle'
                  className='px-[4px] cursor-pointer'></Lozenge>
                {row.original.pickUpStores > 0 ? (
                  <span className='uppercase'>{`Pick up in ${row.original.pickUpStores} ${
                    row.original.pickUpStores === 1 ? 'store' : 'stores'
                  }`}</span>
                ) : null}
              </button>
            </div>
          )
        }
      },
      {
        Header: 'Quantity',
        accessor: 'stock',
        cellHeaderClassName: 'w-[248px]',
        Cell: ({ row, data }: any) => {
          const handleEdit = (val: any) => {
            const newValue = val
            handleCellEdit(row.index, newValue, data)
          }
          const handleEditClear = () => {
            handleClear(row.index, data)
          }

          return (
            <div className='flex items-center gap-x-[8px]'>
              <QuantityPicker
                componentId={row.original.sku}
                className='!rounded'
                // defaultValue={row.original.cartQty}  //if you want you can set default values
                isMinusValueAllowed={false}
                onChange={(e, val: any) => handleEdit(val)}
                restInputProps={{
                  onChange: (e: any) => {
                    if (e.target.value.length > 5) {
                      return
                    }
                    handleEdit(e.target.value)
                  }
                }}
                defaultValue={row.original.cartQty}
                key={`${row.original.sku}`}
                maxValue={10000}
              />
              <div
                className={CN({
                  invisible: !row.original.isClearBtnVisible,
                  visible: row.original.isClearBtnVisible || row.original.cartQty > 0
                })}>
                <Button
                  componentId={`clear-btn-${row.index}`}
                  appearance='tertiary-orange'
                  onClick={() => handleEditClear()}>
                  Clear
                </Button>
              </div>
            </div>
          )
        }
      }
    ],
    []
  )

  if (isPending) {
    return (
      <div className='h-[700px] flex items-center justify-center'>
        <Spinner loop />
      </div>
    )
  }

  const RenderImage = (imageData: any) => {
    return (
      <div className='relative w-[68px] h-[68px] md:w-[52px] md:h-[52px]'>
        <NextImage
          imgKey={`${imageData?.product?.sku}-${imageData?.product?.id}`}
          width={600}
          height={600}
          imageUrl={`${imageData?.product?.image?.url}`}
          alt={`${imageData?.product?.image?.alt || imageData?.product?.title}`}
        />
      </div>
    )
  }

  return (
    <>
      <div className='md:w-full gap-[24px] flex flex-col'>
        <HeaderProductCard
          className='w-auto'
          isCatalogue={productVariants?.product?.isCatalogue}
          subTitle='Catalogue items marked with'
          nextImage={RenderImage(productVariants)}
          thumbnail={{}}
          title={productVariants?.product?.title}
        />
        <div className='flex gap-[24px] min-h-[24px] flex-wrap'>
          {productVariants?.selectedProducts?.length > 0 &&
            productVariants.selectedProducts.map(({ id, cartQty, sku }: any) => {
              return (
                <div className='flex gap-[4px]' key={id}>
                  <Badge
                    onClick={() => {}}
                    view='subtle'
                    className='!rounded !text-B-400 !font-medium'>
                    {sku}
                  </Badge>
                  x {cartQty}
                </div>
              )
            })}
        </div>
      </div>

      {/* Show Desktop Modal when Screen is lg */}
      <div className={ProductVariantsClasses} {...restProps}>
        <DataTable columns={COLUMNS} data={productVariants.tableData || []} />
      </div>

      <div className='flex flex-col flex-shrink-0 lg:!hidden'>
        {productVariants?.tableData?.map(
          (
            {
              sku,
              isCatalogue,
              isRestricted,
              isDeal,
              isClearance,
              id,
              colorDescription,
              colorValues,
              priceWithGst,
              cartQty,
              sizeCode,
              pickUpStores,
              expectedDeliveryDate,
              stock,
              price
            }: any,
            index: number
          ) => {
            return (
              <ul
                key={`${id} - ${sku}`}
                className='flex flex-col w-full border-t py-[16px] border-t-N-100 text-sm gap-[12px]'>
                <li className='flex justify-between w-full'>
                  <span className='text-N-500'>Size</span>
                  <span className='text-N-700'>{sizeCode}</span>
                </li>
                <li className='flex items-center justify-between w-full'>
                  <span className='text-N-500'>Code</span>
                  <div className='flex gap-[6px] flex-col'>
                    <span className='flex gap-[12px]'>
                      {sku}
                      {isCatalogue && (
                        <Lozenge
                          appearance='subtle'
                          hasClickEffect
                          icon='nzsbi-catalogue'
                          onClick={() => {}}
                        />
                      )}
                    </span>
                    {isRestricted ||
                      isDeal ||
                      (isClearance && (
                        <div className='flex flex-col gap-[6px] items-end'>
                          {isRestricted && (
                            <Lozenge appearance='fill' variant='warning-1'>
                              RESTRICTED SUPPLY
                            </Lozenge>
                          )}
                          {isDeal && (
                            <Lozenge appearance='fill' variant='danger'>
                              Deal
                            </Lozenge>
                          )}
                          {isClearance && (
                            <Lozenge appearance='outline' variant='danger'>
                              Clearance
                            </Lozenge>
                          )}
                        </div>
                      ))}
                  </div>
                </li>
                <li className='flex justify-between w-full'>
                  <span className='text-N-500'>Colour</span>
                  <ColorBlock colorList={colorValues} description={colorDescription} />
                </li>
                <li className='flex justify-between w-full'>
                  {roles?.includes('PUNCH_OUT_USER') ? (
                    <>
                      <span className='text-N-500'>Price (Excel. GST)</span>
                      <span className='text-N-700'>${price}</span>
                    </>
                  ) : (
                    <>
                      <span className='text-N-500'>Price (Incl. GST)</span>
                      <span className='text-N-700'>${priceWithGst}</span>
                    </>
                  )}
                </li>
                <li className='flex justify-between w-full'>
                  <span className='text-N-500'>Availability</span>
                  <div className='flex gap-[16px] items-start'>
                    <button
                      className={CN(`flex items-center justify-center gap-[8px] cursor-pointer`)}
                      onKeyDown={() => {}}
                      onClick={(e: any) => {
                        e.preventDefault()
                        openDispatchModal()
                        setDispatchModalDetails({
                          sku: sku,
                          id: productVariants?.product?.id,
                          store: {
                            count: pickUpStores
                          },
                          name: productVariants?.product?.title,
                          expectedDispatchDate: expectedDeliveryDate,
                          stock: stock
                        })
                      }}>
                      <Lozenge
                        icon='nzsbi-truck'
                        variant={stock > 0 ? 'success' : 'warning-2'}
                        appearance='subtle'
                        className='px-[4px]'></Lozenge>
                      {stock > 0 && <span className='uppercase'> {stock}</span>}

                      {stock === 0 && pickUpStores > 0 && (
                        <span>
                          {expectedDeliveryDate ? convertDate(expectedDeliveryDate) : `30+ Days`}
                        </span>
                      )}

                      {stock === 0 && pickUpStores === 0 && <span>{`30+ Days`}</span>}
                    </button>
                    <button
                      className={CN(`flex items-center justify-center gap-[8px] cursor-pointer`)}
                      onKeyDown={() => {}}
                      onClick={(e: any) => {
                        e.preventDefault()
                        if (pickUpStores > 0) {
                          openPickupModal()
                          setPickupModalDetails({
                            sku: sku,
                            id,
                            store: {
                              count: pickUpStores
                            },
                            name: productVariants.product?.title,
                            expectedDispatchDate: convertDate(expectedDeliveryDate),
                            stock: stock,
                            image: productVariants.product?.image
                          })
                        } else {
                          openNoStockPickupModal()
                          setNoStockPickupModalDetails({
                            sku: sku,
                            store: {
                              count: pickUpStores
                            },
                            name: productVariants.product?.title,
                            expectedDispatchDate: convertDate(expectedDeliveryDate),
                            stock: stock,
                            image: productVariants.product?.image
                          })
                        }
                      }}>
                      <Lozenge
                        icon='nzsbi-location-pin'
                        variant={pickUpStores > 0 ? 'success' : 'disabled'}
                        appearance='subtle'
                        className='px-[4px]'></Lozenge>
                      {pickUpStores > 0 ? <span className='uppercase'>{pickUpStores}</span> : null}
                    </button>
                  </div>
                </li>
                <li className='flex w-full'>
                  <QuantityPicker
                    componentId={sku}
                    defaultValue={cartQty}
                    isMinusValueAllowed={false}
                    className='!w-4/5'
                    inputClassName='!w-full'
                    restInputProps={{
                      onChange: (e: any) => {
                        if (e.target.value.length > 5) {
                          return
                        }
                        handleCellEdit(index, Number(e.target.value), productVariants.tableData)
                      }
                    }}
                    onChange={(e, val: any) =>
                      handleCellEdit(index, val, productVariants.tableData)
                    }
                    maxValue={10000}
                  />
                  <Button
                    className='w-1/3'
                    appearance='tertiary-orange'
                    onClick={() => handleClear(index, productVariants.tableData)}>
                    Clear
                  </Button>
                </li>
              </ul>
            )
          }
        )}
      </div>
    </>
  )
}

export default ProductVariants
