import * as Yup from 'yup'

export const SignUpSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  mobileNumber: Yup.string()
    .matches(/^\d+$/, 'Must be only digits')
    .min(6, 'Invalid mobile number')
    .max(12, 'Invalid mobile number'),
  companyName: Yup.string(),
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password is too short')
    .matches(/^(?=.*[a-z])/, 'Password should have at least one characters')
    .matches(/^(?=.*[A-Z])/, 'Password should have at least one upper case characters')
    .matches(/^(?=.*\d)/, 'Password should have at least one numeric value')
    .matches(
      /^(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>/?])/,
      'Password should have at least one special character'
    ),
  isSubscribed: Yup.boolean().required('Required')
})
