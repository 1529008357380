import { useQuery } from '@tanstack/react-query'
import { httpGET } from 'lib/http'

import { InvoiceStatus, InvoiceTypes } from 'components/molecules'

export interface IGetInvoiceDetailsResponse {
  messageTitle: string | null
  messageDescription: string | null
  messageType: string
  data: IInvoiceDetails
}

export enum DeliveryOption {
  PICKUP = 0,
  DELIVERY = 1
}

export interface IInvoiceOrderLine {
  id: number
  sku: string
  description: string
  uom: string
  orderedQty: number
  suppliedQty: number
  backOrderQty: number
  price: number
  priceWithGst: number
  value: number
  valueWithGST: number
}

export interface IInvoiceDetailsAddress {
  firstName: string
  lastName: string
  contactNumber: string
  customerName: string
  businessName: string
  addressDetails: string
  streetAddress: string
  city: string
  suburb: string
  country: string
  postCode: string
  attentionTo: string
}

export interface IInvoicePickupDetails {
  pickupBranch: string
  preferredDate: string
  pickupPersonFirstName: string
  pickupPersonLastName: string
  pickupPersonContactNumber: string
  pickupPersonEmailAddress: string
  branchCode: string
  branchName: string
  postCode: string
  city: string
  suburb: string
  streetAddress: string
}

export interface IInvoiceDetails {
  balance: number
  balanceWithGST: number
  customerNumber: string
  customerPOReference: string
  deliveryOption: DeliveryOption
  deliverySequence: string
  despatchDate: string
  despatchLocation: string
  gst: number
  invoiceAddress: IInvoiceDetailsAddress
  invoiceNumber: string
  invoiceType: InvoiceTypes
  isPDFAvailable: true
  orderDate: string
  orderLines: IInvoiceOrderLine[]
  orderNumber: string
  pickupDetails: IInvoicePickupDetails
  shippingAddress: IInvoiceDetailsAddress
  shippingCharge: number
  shippingChargeWithGST: number
  status: InvoiceStatus
  subTotal: number
  subTotalWithGST: number
  total: number
  totalWithGST: number
}

export const getInvoiceDetails = async (invoiceId: string, context?: any) => {
  const { data } = await httpGET({
    url: `invoice/getinvoices/${invoiceId}`,
    context
  })
  return data?.data
}

export const useGetInvoiceDetails = (invoiceId: string) => {
  return useQuery({
    queryKey: ['GET_INVOICE_DETAILS', invoiceId],
    queryFn: () => getInvoiceDetails(invoiceId)
  })
}
