import { useRouter } from 'next/router'
import React, { FC, useEffect, useState } from 'react'
import CN from 'classnames'

interface SidePaneProps {
  className?: string
  componentId?: string
  isActive?: boolean
  focusableSidePanelList?: {
    id: number
    itemName: string
  }[]
}

export const SidePane: FC<SidePaneProps> = ({
  className,
  componentId,
  isActive,
  focusableSidePanelList,
  ...restProps
}: SidePaneProps) => {
  const FocusableSidePanelClasses = CN(
    `focusable-side-panel h-full w-full hidden lg:flex`,
    className
  )
  const router = useRouter()
  const [activeItem, setActiveItem] = useState<number>(1)

  const scrollToElement = (element: string) => {
    if (element) {
      const el: any = document.getElementById(element)
      el?.scrollIntoView({ behavior: 'smooth' })
    }
  }

  useEffect(() => {
    const fragment = window.location.hash
    const originalHash = decodeURIComponent(fragment.substring(1))
    const matchedIndex: any = focusableSidePanelList?.findIndex(
      item => item?.itemName === originalHash
    )
    const selectedIndex = matchedIndex !== -1 ? matchedIndex + 1 : 1

    setActiveItem(selectedIndex)
  }, [focusableSidePanelList])

  const handleClick = (item: any) => {
    setActiveItem(item?.id)
    const sectionRef = item?.itemName
    if (sectionRef) {
      scrollToElement(sectionRef)
      router.push(`#${item?.itemName}`, undefined, { scroll: false })
    }
  }

  return (
    <div className={FocusableSidePanelClasses} {...restProps} data-component-id={componentId}>
      <div
        className={CN(
          'focusable-side-panel-button flex w-full flex-col border-l-Gray-200 text-left h-fit gap-[8px]'
        )}
        data-component-id={`${componentId}-side-panel`}>
        {focusableSidePanelList?.map((item, id: number) => (
          <button
            tabIndex={0}
            key={`${item?.id ?? id}-side-panel-item`}
            data-component-id={`${componentId}-side-panel-item-${id}`}
            className={CN(
              'flex py-[8px] px-[16px] cursor-pointer hover:bg-N-25 rounded text-left',
              {
                'text-sm text-B-500 font-500 cursor-default': activeItem === item?.id,
                'text-sm text-N-700 font-400 text-small hover:text-Gray-600':
                  activeItem !== item?.id
              }
            )}
            onClick={() => {
              handleClick(item)
            }}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                handleClick(item)
              }
            }}>
            {item?.itemName}
          </button>
        ))}
      </div>
    </div>
  )
}

export default SidePane
