import React, { FC, useEffect, useState } from 'react'
import { Lozenge, LozengeProps, Overlay } from '@nzsb/shopnx-ui'
import CN from 'classnames'
import useWindowDimensions from 'lib/hooks/useWindowDimensions'
import { flagTypeMap } from 'lib/static/productStaticData'
import { getSizeForBreakPoint } from 'lib/utilities'

import { NextImage } from 'components/atoms'

import { HorizontalCarousel } from './HorizonalCarousel'

export interface ProductImagePanelProps {
  [x: string]: any
  className?: string
  defaultImage?: HorizontalImageProps
  hideSlider?: boolean
  images: HorizontalImageProps[]
  onImageSelect?: (image: HorizontalImageProps) => void
  productFlag?: 0 | 1 | 2 | 3 | 4 | 5
  sku: string
}

export interface HorizontalImageProps {
  alt: string
  height?: number
  imgUrl: string
  key: string
  zoomImgUrl: string
}

export const ProductImagePanel: FC<ProductImagePanelProps> = ({
  className,
  defaultImage,
  hideSlider,
  images,
  onImageSelect,
  productFlag,
  sku,
  ...restProps
}: ProductImagePanelProps) => {
  const ProductImagePanelClasses = CN(
    `product-image-carousel w-full overflow-hidden relative`,
    className
  )

  const [selectedImage, setSelectedImage] = useState(defaultImage || images[0])

  const [isOpenZoomView, setIsOpenZoomView] = useState(false)

  useEffect(() => {
    if (isOpenZoomView) {
      if (typeof window != 'undefined' && window.document) {
        const scrollBarCompensation = window.innerWidth - document.body.offsetWidth
        document.body.style.overflow = 'hidden'
        document.body.style.paddingRight = `${scrollBarCompensation}px`
      }
    } else if (typeof window != 'undefined' && window.document) {
      document.body.style.overflow = ''
      document.body.style.paddingRight = ''
    }
  }, [isOpenZoomView])

  // If images changes set the defualt image to first image
  useEffect(() => {
    setSelectedImage(images[0])
  }, [images])

  const onCloseZoomView = () => {
    setIsOpenZoomView(false)
  }

  const opneZoomView = () => {
    if (!selectedImage?.zoomImgUrl) return
    setIsOpenZoomView(true)
  }

  const onSliderImageSelection = (image: HorizontalImageProps) => {
    setSelectedImage(image)
    if (onImageSelect) {
      onImageSelect(image)
    }
  }
  const { width } = useWindowDimensions() || {}

  const imgHeight = getSizeForBreakPoint({
    width: width,
    xs: 288,
    sm: 288,
    md: 424,
    lg: 560,
    xl: 560
  })

  const imgWidth = getSizeForBreakPoint({
    width: width,
    xs: 365,
    sm: 365,
    md: 624,
    lg: 693,
    xl: 693
  })

  useEffect(() => {
    if (defaultImage) {
      setSelectedImage(defaultImage)
    }
  }, [defaultImage, images])

  return (
    <div
      data-component-id='product-image-carousel'
      className={ProductImagePanelClasses}
      {...restProps}>
      {productFlag !== undefined && productFlag > 0 && (
        <Lozenge
          data-component-id={`product-image-${sku}-flag`}
          className={CN('absolute uppercase z-10 ', {
            'whitespace-nowrap': productFlag === 4
          })}
          isFlag
          variant={flagTypeMap[productFlag].variant as LozengeProps['variant']}
          appearance={flagTypeMap[productFlag].appearance as LozengeProps['appearance']}>
          {flagTypeMap[productFlag].displayValue}
        </Lozenge>
      )}
      <button
        className='relative w-full h-[328px] md:h-[464px] lg:h-[600px] border rounded border-N-100 p-[20px] flex items-center justify-center'
        onClick={opneZoomView}
        disabled={!selectedImage?.imgUrl}>
        {selectedImage?.imgUrl && (
          <NextImage
            useWithDimensions={true}
            imgKey={selectedImage?.key || selectedImage?.alt}
            alt={selectedImage?.alt}
            imageUrl={selectedImage?.imgUrl}
            height={imgHeight}
            width={imgWidth}
            className='!w-full !h-full !object-contain'
          />
        )}

        {/* <RenderImage {...images[selectedImageIndex]} /> */}
        <i className='nzsbi-zoom-in absolute top-[20px] right-[20px] text-N-900 cursor-pointer text-[24px]' />
      </button>

      <div
        className={CN('w-full h-[80px] mt-[20px] flex items-center box-border', {
          hidden: hideSlider
        })}>
        <HorizontalCarousel
          images={images}
          gapBetweenSlides={24}
          slidesPerView={4}
          selectedMainImage={selectedImage}
          onSlideItemClick={onSliderImageSelection}
        />
      </div>

      {isOpenZoomView && (
        <div className='fixed top-0 left-0 w-screen h-screen z-[99999999]'>
          <Overlay
            onClick={onCloseZoomView}
            className='!p-2 sm:!p-[30px] md:!p-[40px] lg:!p-[60px] flex items-center justify-center'>
            <div className='relative flex items-center justify-center bg-N-400 bg-opacity-[80%] shadow-2xl w-full h-full'>
              <button className='absolute right-2 top-2' onClick={onCloseZoomView}>
                <i className='nzsbi-close text-[24px]' />
              </button>
              <NextImage
                imgKey={selectedImage.key ?? ''}
                alt={selectedImage.alt}
                imageUrl={selectedImage.imgUrl}
              />
            </div>
          </Overlay>
        </div>
      )}
    </div>
  )
}

export default ProductImagePanel
