import React, { FC } from 'react'
import { Modal, ModalBody, ModalHeader } from '@nzsb/shopnx-ui'
import CN from 'classnames'
import { useModalContext } from 'lib/contexts/modal-context'

export interface INoStockPickUpModalProps {
  className?: string
}

export const NoStockPickUpModal: FC<INoStockPickUpModalProps> = ({
  className,

  ...restProps
}: INoStockPickUpModalProps) => {
  const NoStockPickUpModalClasses = CN(`expected-dispatch-modal`, className)

  const { noStockPickUpModal } = useModalContext()
  const { isOpen, size, color, sku, close, name } = noStockPickUpModal

  return (
    <div className={NoStockPickUpModalClasses} {...restProps}>
      <Modal
        componentId='forgot-password-modal'
        isOpen={isOpen}
        onClickOverlay={close}
        className='w-[444px] flex-shrink-0'>
        <ModalHeader
          componentId='forgot-password-modal'
          onClose={close}
          heading={'No In-store Availability'}
          hasBorder={false}
        />
        <ModalBody>
          <div className='text-base font-bold text-N-700 mb-[4px]'>{name}</div>
          <div className='text-sm font-normal text-N-600 mb-[4px]'>{sku}</div>
          {size && color && (
            <div className='flex flex-row text-sm font-normal text-N-600 mb-[16px]'>
              <div className='mr-[24px]'>
                <span>Size: </span>
                <span className='font-bold'>{size}</span>
              </div>
              <div>
                <span>Colour: </span>
                <span className='font-bold'>{color}</span>
              </div>
            </div>
          )}

          <div className='text-base font-normal text-N-700 mt-[16px]'>
            This item is available for delivery only.
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default NoStockPickUpModal
