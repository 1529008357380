import { useRouter } from 'next/router'
import React, { FC, useState } from 'react'
import { Accordion } from '@nzsb/shopnx-ui'
import CN from 'classnames'

export interface ISingleEntry {
  id?: number
  name?: string
  urlSlug?: string
  urlPaths?: string
}

export interface ISideBarAccordionProps {
  className?: string
  componentId?: string
  isMobile?: boolean
  items?: Array<ISingleEntry>
  onClickItem?: (item: ISingleEntry) => void
  title: string
}

interface ContentLevelProps {
  componentId?: string
  items?: Array<ISingleEntry>
  onClickItem?: (item: ISingleEntry) => void
}

const ContentLevel = ({ componentId, items, onClickItem }: ContentLevelProps) => {
  const router = useRouter()
  return (
    <div className='flex flex-col text-sm font-400 font-sans text-N-700'>
      {items?.map((item: ISingleEntry, index: number) => (
        <button
          data-component-id={`${componentId}-all-categories-item-${index}`}
          className={CN('text-start text-sm my-2 hover:text-N-200', {
            'text-B-400': item.urlSlug && router.asPath.includes(item.urlSlug)
          })}
          key={`item-${item?.id ?? index}`}
          onClick={() => onClickItem && onClickItem(item)}>
          {item.name}
        </button>
      ))}
    </div>
  )
}

export const SideBarAccordion: FC<ISideBarAccordionProps> = ({
  className,
  componentId,
  isMobile,
  items,
  onClickItem,
  title,
  ...restProps
}: ISideBarAccordionProps) => {
  const SideBarAccordionClasses = CN(
    `${componentId}-side-bar-filters h-fit min-w-[296px] flex-col`,
    { 'hidden lg:!flex': !isMobile },
    { flex: isMobile },
    className
  )

  const [expanded, setExpanded] = useState<false | number>(0)

  return (
    <div
      className={SideBarAccordionClasses}
      {...restProps}
      data-component-id={`${componentId}-all-categories`}>
      <Accordion
        className='w-full py-[8px] !border-b-0'
        headerClasses='!capitalize !text-N-700'
        setExpanded={setExpanded}
        expanded={expanded}
        item={{
          id: 0,
          content: (
            <ContentLevel
              componentId={`${componentId}-all-categories-items`}
              items={items}
              onClickItem={onClickItem}
            />
          ),
          title
        }}
        variant='white'
      />
    </div>
  )
}

export default SideBarAccordion
