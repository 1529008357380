import React, { FC, forwardRef, useEffect, useRef, useState } from 'react'
import { Button } from '@nzsb/shopnx-ui'
import CN from 'classnames'
import { Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import { NextImage } from 'components/atoms'

import { HorizontalImageProps } from './ProductImagePanel'

export interface HorizontalCarouselProps {
  className?: string
  gapBetweenSlides?: number
  hideButtonPanel?: boolean
  images: HorizontalImageProps[]
  onSlideItemClick?: (image: HorizontalImageProps) => void
  selectedMainImage?: HorizontalImageProps
  slideItemClass?: string
  slidesPerView?: number | 'auto'
}

export const HorizontalCarousel = forwardRef<FC, HorizontalCarouselProps>(
  (
    {
      className,
      gapBetweenSlides,
      hideButtonPanel,
      images,
      onSlideItemClick,
      selectedMainImage,
      slideItemClass,
      slidesPerView,
      ...restProps
    }: HorizontalCarouselProps,
    ref: any
  ) => {
    const HorizontalCarouselClasses = CN('HorizontalCarousel flex w-full h-full', className)
    const slideItem = CN(
      `slide-item flex bg-white p-[8px] border border-N-100 rounded max-w-[87px] max-h-[80px] md:max-w-[151px] md:max-h-[80px] lg:!max-w-[168.25px] lg:!max-h-[80px]`,
      slideItemClass
    )

    const swiperRef = useRef<any>()

    /** Control button enable disable status   */
    const [allowNext, setAllowNext] = useState(true)
    const [allowPrevious, setAllowPrevious] = useState(false)

    const [selectedImage, setSelectedImage] = useState<HorizontalImageProps | undefined>(
      selectedMainImage
    )

    const enableButtonPanel =
      !hideButtonPanel && slidesPerView && Number(slidesPerView) < images.length

    const onImageSelection = (image: HorizontalImageProps) => {
      setSelectedImage(image)
      if (onSlideItemClick) {
        onSlideItemClick(image)
      }
    }

    useEffect(() => {
      setSelectedImage(selectedMainImage)
    }, [selectedMainImage])

    return (
      <>
        {images?.length > 4 && (
          <div className={HorizontalCarouselClasses}>
            {enableButtonPanel && (
              <Button
                iconOnly
                appearance='link-gray'
                iconBefore='nzsbi-chevron-left'
                isRingOffset={false}
                disabled={!allowPrevious}
                className='mr-[20px] focus:ring-0 focus:ring-offset-0 focus:shadow-none hover:bg-B-25'
                size='xs'
                onClick={() => swiperRef?.current?.slidePrev()}
              />
            )}
            <Swiper
              ref={ref}
              direction={'horizontal'}
              pagination={{
                clickable: true,
                enabled: false
              }}
              spaceBetween={gapBetweenSlides}
              slidesPerView={slidesPerView}
              modules={[Pagination]}
              onBeforeInit={swiper => {
                swiperRef.current = swiper
              }}
              onReachBeginning={() => {
                setAllowNext(true)
                setAllowPrevious(false)
              }}
              onReachEnd={() => {
                setAllowNext(false)
                setAllowPrevious(true)
              }}
              onSwiper={val => {
                if (val) {
                  setAllowPrevious(val?.allowSlidePrev)
                  setAllowNext(val?.allowSlideNext)
                }
              }}
              className='w-full h-full'
              {...restProps}>
              {images.map((imgProperties, index) => (
                <SwiperSlide
                  key={`image-${index.toString()}`}
                  className={CN(slideItem, {
                    'border !border-B-200': selectedMainImage?.imgUrl === imgProperties?.imgUrl
                  })}
                  onClick={() => {
                    onImageSelection(imgProperties)
                  }}>
                  <NextImage
                    imgKey={imgProperties?.key}
                    alt={imgProperties?.alt}
                    height={420}
                    width={693}
                    imageUrl={imgProperties?.imgUrl}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            {enableButtonPanel && (
              <Button
                iconOnly
                appearance='link-gray'
                iconBefore='nzsbi-chevron-right'
                size='xs'
                isRingOffset={false}
                disabled={!allowNext}
                className='ml-[20px] focus:ring-0 focus:ring-offset-0 focus:shadow-none hover:bg-B-25'
                onClick={() => swiperRef?.current?.slideNext()}
              />
            )}
          </div>
        )}
        {images?.length <= 4 && (
          <div className='flex flex-row w-full gap-[20px] h-[80px]'>
            {images.map(imgProperties => (
              <button
                onClick={() => {
                  onImageSelection(imgProperties)
                }}
                className={CN('flex flex-1 relative h-[80px] border-box border  rounded', {
                  'max-w-[80px] md:max-w-[151px] lg:max-w-[168px]': images?.length < 4,
                  'border-B-400': selectedImage?.imgUrl === imgProperties?.imgUrl,
                  'border-N-100': selectedImage?.imgUrl !== imgProperties?.imgUrl
                })}
                key={imgProperties?.key}>
                <NextImage
                  imgKey={''}
                  alt={imgProperties?.key}
                  height={420}
                  width={693}
                  imageUrl={imgProperties?.imgUrl}
                />
              </button>
            ))}
          </div>
        )}
      </>
    )
  }
)

HorizontalCarousel.displayName = 'HorizontalCarousel'

export default HorizontalCarousel
