import React, { FC } from 'react'

export interface IYouTubeIFrameProps {
  className?: string
  youtubeLink: string
  componentId?: string
  [x: string]: any
}

export const YouTubeIFrame: FC<IYouTubeIFrameProps> = ({
  className,
  youtubeLink,
  componentId,
  ...restProps
}: IYouTubeIFrameProps) => {
  // get video Id
  const regex = /(?:youtu\.be\/|youtube\.com\/(?:.*[?&]v=|embed\/|v\/))([\w-]{11})/
  const match = regex.exec(youtubeLink)

  // Check if there's a match and return the video ID, or null if no match
  const id = match ? match[1] : null

  const embedUrl = `https://youtube.com/embed/${id}`

  return (
    <iframe
      id={`youtube-video-${componentId}`}
      className={className}
      src={embedUrl}
      title='YouTube video player'
      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
      allowFullScreen
      {...restProps}></iframe>
  )
}

export default YouTubeIFrame
