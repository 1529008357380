import React, { FC, useEffect, useRef, useState } from 'react'
import CN from 'classnames'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Swiper as SwiperCore } from 'swiper/types'

import { NextImage } from 'components/atoms'

export interface IProductImageCarouselProps {
  className?: string
  productImages: Array<{
    id: string
    attributes: {
      alternativeText: string | null
      provider_metadata: {
        public_id: string
        resource_type: string
      }
    }
  }>
}

export const ProductImageCarousel: FC<IProductImageCarouselProps> = ({
  className,
  productImages,
  ...restProps
}: IProductImageCarouselProps) => {
  const ProductImageCarouselClasses = CN(
    `ProductImageCarousel relative w-full h-[200px] md:!w-[200px]`,
    className
  )

  const swiperRef = useRef<SwiperCore>()

  const [activeSlideIndex, setActiveSlideIndex] = useState(0)
  const [isButtonShow, setIsButtonShow] = useState(false)

  const onPaginationButtonClick = (index: number) => {
    swiperRef.current?.slideToLoop(index)
  }

  const handleHoverOrFocus = () => {
    setIsButtonShow(true)
  }

  useEffect(() => {
    if (productImages?.length > 1) {
      swiperRef?.current?.slideToLoop(0)
    }
  }, [productImages])

  return (
    <div
      tabIndex={0}
      role='button'
      className={ProductImageCarouselClasses}
      onMouseOver={handleHoverOrFocus}
      onFocus={handleHoverOrFocus}
      onMouseLeave={() => setIsButtonShow(false)}
      {...restProps}>
      {/* ProductImageCarousel Left Pagination button */}
      {productImages?.length > 1 && (
        <button
          disabled={activeSlideIndex === 0}
          className={CN(
            'flex items-center opacity-0 justify-center absolute top-[55%] left-[5%] mt-[-24px] w-[30px] h-[30px] bg-white z-50 border border-N-100 rounded transition-opacity duration-500 ease-out',
            {
              '!opacity-40': isButtonShow && activeSlideIndex === 0,
              'opacity-100': isButtonShow
            }
          )}
          onClick={() => swiperRef.current?.slidePrev()}>
          <i className='nzsbi-chevron-left text-N-700 text-xs' />
        </button>
      )}

      {/* ProductImageCarousel Slider start */}
      <Swiper
        loop={false}
        centeredSlides
        slidesPerView={'auto'}
        className='w-full h-full'
        onBeforeInit={swiper => {
          swiperRef.current = swiper
        }}
        onRealIndexChange={val => {
          setActiveSlideIndex(val.realIndex)
        }}>
        {productImages?.map((image, index) => (
          <SwiperSlide
            key={`product-image-${image?.id ?? index}`}
            className={CN('!px-0 !flex !justify-center !items-center h-full w-full', {
              'pl-[12px]': index === 0 && productImages?.length > 1
            })}>
            <div className='relative w-[150px] h-[200px]'>
              <NextImage
                data-component-id={`product-image-${image?.id || index}`}
                imgKey={image?.id}
                system='CMS'
                imageUrl={image?.attributes?.provider_metadata?.public_id}
                useWithDimensions={false}
                alt={image?.attributes?.alternativeText ?? 'ProductImageCarousel Slide'}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      {/* ProductImageCarousel Slider end */}

      {/* ProductImageCarousel pagination right button */}
      {productImages?.length > 1 && (
        <button
          disabled={activeSlideIndex === productImages?.length - 1}
          className={CN(
            'justify-center items-center opacity-0 absolute mt-[-24px] top-[55%] right-[5%] w-[30px] h-[30px] bg-white z-40 border border-N-100 rounded transition-opacity duration-500 ease-out',
            {
              '!opacity-40': isButtonShow && activeSlideIndex === productImages?.length - 1,
              'opacity-100': isButtonShow
            }
          )}
          onClick={() => swiperRef.current?.slideNext()}>
          <i className='nzsbi-chevron-right text-N-700 text-xs' />
        </button>
      )}

      {/* swiper pagination */}
      {productImages?.length > 1 && (
        <div className='flex justify-center items-center absolute bottom-4 left-[50%] -translate-x-1/2 z-50 bg-black/[.51] gap-[6px] px-2 py-1 rounded-full'>
          {productImages?.map((image, index) => (
            <div
              tabIndex={0}
              role='button'
              key={`${image?.id}-product-image-item`}
              className={CN('w-2 h-2 rounded-full bg-white cursor-pointer', {
                '!bg-O-500': index === activeSlideIndex
              })}
              onClick={() => onPaginationButtonClick(index)}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  onPaginationButtonClick(index)
                }
              }}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default ProductImageCarousel
