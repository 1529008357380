import React, { FC, useEffect, useState } from 'react'
import CN from 'classnames'
import { useGetFooter } from 'lib/actions'
import { useLogout } from 'lib/actions/auth/logout'
import { useAppContext } from 'lib/contexts/app-context'
import { FOOTER_SOCIAL_ICONS_DATA } from 'lib/static/dummy-data'
import {
  ACCOUNT_LOGGED_OUT_USER_BLOCK_DATA,
  LOGGED_IN_HELP_CONTAINER_BLOCK_DATA,
  LOGGED_OUT_HELP_CONTAINER_BLOCK_DATA
} from 'lib/static/footerData'

import { BrandingInfo, BrandingInfoProps } from './BrandingInfo'
import { Copyright, CopyrightProps } from './Copyright'
import { NavBlock } from './NavBlock'
import { SocialIcons, SocialIconsProps } from './SocialIcons'

export interface FooterProps {
  [x: string]: any
  brandingInfo?: BrandingInfoProps
  className?: string
  copyright?: CopyrightProps
  navBlocks?: any
  socialIcons?: SocialIconsProps
}

export const Footer: FC<FooterProps> = ({
  brandingInfo,
  className,
  copyright,
  navBlocks,
  socialIcons,
  ...restProps
}: FooterProps) => {
  const FooterClasses = CN(`footer bg-B-500`, className)

  const { user, isLoggedIn, session } = useAppContext()
  const [isLinked, setIsLinked] = useState(false)

  const { logout } = useLogout()

  useEffect(() => {
    if (
      user?.data?.emailAddress &&
      (user?.data?.emailAddress === 'jhone@mailinator.com' ||
        user?.data?.emailAddress === 'wilson@mailinator.com' ||
        user?.dat?.emailAddress === 'franky@mailinator.com')
    ) {
      setIsLinked(true)
    } else if (
      user?.data?.accountCode === 'WEBCARD 001' ||
      user?.data?.accountCode === undefined ||
      user?.data?.accountCode === null
    ) {
      setIsLinked(false)
    } else {
      setIsLinked(true)
    }
  }, [user])

  const { data: footerData } = useGetFooter()

  let ACCOUNT_CONTAINER_NAV_LIST

  if (isLoggedIn) {
    if (isLinked) {
      ACCOUNT_CONTAINER_NAV_LIST = [
        {
          id: 1,
          label: 'Sign Out',
          link: 'https://www.google.com',
          onClick: () => {
            logout()
          },
          hidden: session?.isPunchOut && session?.isIframe
        },
        {
          id: 2,
          label: 'My Account',
          href: '/account'
        }
      ]
    } else {
      ACCOUNT_CONTAINER_NAV_LIST = [
        {
          id: 1,
          label: 'Sign Out',
          onClick: () => {
            logout()
          },
          hidden: session?.isPunchOut && session?.isIframe
        },
        {
          id: 2,
          label: 'My Account',
          href: '/account'
        },
        {
          id: 3,
          label: 'Request a Credit Account',
          href: '/coming-soon'
        }
      ]
    }
  } else {
    ACCOUNT_CONTAINER_NAV_LIST = ACCOUNT_LOGGED_OUT_USER_BLOCK_DATA
  }

  const FOOTER_LINKS_BLOCKS_DATA = [
    {
      id: 1,
      heading: footerData?.menu1?.header,
      navList: footerData?.menu1?.items
    },
    {
      id: 2,
      heading: footerData?.menu2?.header,
      navList: footerData?.menu2?.items
    },
    {
      id: 3,
      heading: 'Account',
      navList: ACCOUNT_CONTAINER_NAV_LIST
    },
    {
      id: 4,
      heading: 'Help & Support',
      navList: isLoggedIn
        ? LOGGED_IN_HELP_CONTAINER_BLOCK_DATA
        : LOGGED_OUT_HELP_CONTAINER_BLOCK_DATA
    }
  ]

  return (
    <footer data-component-id='footer' className={FooterClasses} {...restProps}>
      <div className=' container pt-[24px] sm:pt-[40px]'>
        <div className='lg:grid lg:grid-cols-12 pb-[8px] lg:pb-0'>
          <div className='flex flex-col lg-[34px] footer__branding sm:items-center lg:items-start lg:mb-0 sm:!flex-row sm:!justify-between lg:!flex-col lg:!justify-start lg:col-span-4 lg:gap-y-[18px] mb-[32px] sm:mb-[0px]'>
            <BrandingInfo
              className='cursor-pointer'
              logo={{ appearance: 'primary', variant: 'stacked' }}
              cta={{
                heading: 'Call Us',
                number: '0800 660 660'
              }}
            />
            <SocialIcons heading='Experience Our Mobile App' icons={FOOTER_SOCIAL_ICONS_DATA} />
          </div>
          <div className='grid grid-cols-2 footer__nav-blocks gap-[34px] sm:grid-cols-4 sm:gap-[20px] lg:col-span-8'>
            {FOOTER_LINKS_BLOCKS_DATA.map((nav: any) => (
              <NavBlock
                key={nav?.id}
                heading={nav?.heading}
                navList={nav?.navList}
                navId={nav?.id}
              />
            ))}
          </div>
        </div>
        <Copyright
          companyName='NZ Safety Blackwoods'
          year={new Date().getFullYear().toString()}
          links={[
            { id: 1, label: 'Privacy Policy', href: '/terms/privacy-policy' },
            { id: 2, label: 'Terms & Conditions', href: '/terms/terms-and-conditions' },
            { id: 3, label: 'Disclaimer', href: '/terms/disclaimer' }
          ]}
        />
      </div>
    </footer>
  )
}

Footer.defaultProps = {
  brandingInfo: undefined,
  className: undefined,
  copyright: undefined,
  navBlocks: undefined,
  socialIcons: undefined
}

export default Footer
