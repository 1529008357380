import { useMutation } from '@tanstack/react-query'
import { httpPOST } from 'lib/http'

export interface IPayStatementsReq {
  statementIds: Array<string>
}

export interface IPayStatementsRes {
  messageTitle: string | null
  messageDescription: string | null
  messageType: string | null
  messageCode: string | null
  data: {
    statementIds: Array<string>
    id: string
    paymentRedirectURL: string
  } | null
}

export const payStatements = async (req: IPayStatementsReq) => {
  const { data } = await httpPOST({
    url: 'statement/pay',
    body: req
  })
  return data as IPayStatementsRes
}

export const usePayStatements = () => {
  return useMutation({
    mutationFn: payStatements
  })
}
