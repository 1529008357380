import type { GetServerSideProps, NextPage } from 'next'
import dynamic from 'next/dynamic'
import CN from 'classnames'
import { remark } from 'remark'
import remarkGfm from 'remark-gfm'
import remarkHtml from 'remark-html'
import { getDealProductsHome, getHomePageDetails } from 'lib/actions'
import { keepCmsSorting } from 'lib/utilities'
import { withSessionSsr } from 'lib/withSession'

import {
  BrandBanner,
  ExpertAdviceSection,
  FeatureBarSection,
  PopularProductCategories,
  SEOBlock,
  SpecialistServicesBlock
} from 'components/molecules'
import { BrandLogoList } from 'components/molecules/BrandLogoList'
import { Head, Hero } from 'components/organisms'

const DealProductList = dynamic(
  () => import('components/molecules/DealProductList/DealProductList'),
  { ssr: true }
)

/* Define the functional component for the page */
const Page: NextPage = (data: any) => {
  /* Destructure data object to extract meta, pageData, and deals */
  const { meta, pageData, prepHomeTemplate } = data

  /* List of blocks with non-White backgrounds [Add any other block __typename if it has non-white BG, when implemented] */
  const nonWhiteBgBlocks = [
    'ComponentMoleculesHomeDealsBlock',
    'ComponentMoleculesHomeServicesBlock'
  ]

  /* Render the main structure of the page */
  return (
    <div className='page'>
      {/* Head component with meta information */}
      <Head {...meta} />

      {/* Hero component with heroSlides data */}
      <Hero heroSlides={pageData?.HomeResponse?.data[0]?.attributes?.HeroSlider} />
      {/* Render DealProductList component if there are deals/products */}
      {prepHomeTemplate?.map((item: any, index: number, arr: any) => {
        const isLastElement = index === prepHomeTemplate.length - 1
        const isNextBlockNonWhiteBg = nonWhiteBgBlocks.includes(arr[index + 1]?.__typename)

        switch (item?.__typename) {
          case 'ComponentMoleculesHomeDealsBlock':
            return (
              <div
                className={CN('container py-[40px]', {
                  'pb-[80px]': isLastElement
                })}
                key={`ComponentMoleculesHomeDealsBlock-${item?.HomeDealsBlockId}`}>
                <DealProductList
                  products={item?.Products}
                  isPromotions
                  title={item.HomeDealsBlockHeader}
                  componentId={`home-deals-${index}`}
                  actionButton={{
                    buttonText: item?.Action?.ButtonText,
                    url: item?.Action?.Url,
                    iconBefore: item?.Action?.IconBefore,
                    iconAfter: item?.Action?.IconAfter
                  }}
                />
              </div>
            )

          case 'ComponentMoleculesHomeBrandBlock':
            return (
              <div
                className={CN('bg-white', {
                  'pb-[40px] lg:pb-[80px]': isNextBlockNonWhiteBg,
                  '!pb-[80px]': isLastElement
                })}
                key={`ComponentMoleculesHomeBrandBlock-${item?.HomeBrandBlockId}`}>
                <div className='container pt-[40px] lg:pt-[80px]'>
                  <BrandLogoList
                    componentId='home-brand-list'
                    brandImagesData={item?.Brands?.data}
                    header={item?.HomeBrandBlockHeader}
                    actionButton={{
                      children: item?.Action?.DisplayText,
                      url: item?.Action?.URL
                    }}
                  />
                </div>
              </div>
            )

          case 'ComponentMoleculesHomeExpertAdviceBlock':
            return (
              <div
                className={CN('bg-white', {
                  'pb-[40px] lg:pb-[80px]': isNextBlockNonWhiteBg,
                  '!pb-[80px]': isLastElement
                })}
                key={`ComponentMoleculesHomeExpertAdviceBlock-${item?.HomeExpertAdviceBlockId}`}>
                <div className='container pt-[40px] lg:pt-[80px]'>
                  <ExpertAdviceSection
                    expertAdviceItems={item}
                    componentId={`home-expert-advice-${index}`}
                  />
                </div>
              </div>
            )

          case 'ComponentMoleculesHomeServicesBlock':
            return (
              <div
                className={CN('container py-[40px]', {
                  'pb-[80px]': isLastElement
                })}
                key={`ComponentMoleculesHomeServicesBlock-${item?.HomeServicesBlockId}`}>
                <div>
                  <SpecialistServicesBlock
                    specialistServices={item}
                    componentId={`home-specialist-services-${index}`}
                  />
                </div>
              </div>
            )

          case 'ComponentMoleculesBanner':
            return (
              <div
                className={CN('bg-white', {
                  'pb-[40px]': isNextBlockNonWhiteBg,
                  '!pb-[80px]': isLastElement
                })}
                key={`ComponentMoleculesBanner-${item?.MoleculesBannerId}`}>
                <div className='container pt-[40px] lg:pt-[80px]'>
                  <BrandBanner
                    bannerData={item}
                    className='!py-0'
                    componentId={`home-banner-${index}`}
                    crop='fill'
                    height={272}
                    imageCustomClassName='!rounded'
                    mobileHeight={250}
                    mobileImageCustomClassName='!rounded !w-full !max-h-[250px]'
                    mobileWidth={390}
                    overlayCustomClassName='!rounded'
                    useWithDimensions
                    width={1280}
                  />
                </div>
              </div>
            )

          case 'ComponentMoleculesIconTiles':
            return (
              <div
                className={CN({
                  'mb-[80px]': index === prepHomeTemplate.length - 1,
                  'bg-B-25': item?.Icons?.length > 0
                })}
                key={`ComponentMoleculesIconTiles-${item.MoleculesIconTilesId}`}>
                <div className='container'>
                  <FeatureBarSection featuresList={item?.Icons} componentId='feature-bar-section' />
                </div>
              </div>
            )

          case 'ComponentMoleculesSeoBlock':
            return (
              <div
                className={CN('bg-white', {
                  'pb-[40px] lg:pb-[80px]': isNextBlockNonWhiteBg,
                  '!pb-[80px]': isLastElement
                })}
                key={`ComponentMoleculesSeoBlock-${item.MoleculesSeoBlockId}`}>
                <div className='container pt-[40px] lg:pt-[80px]'>
                  <SEOBlock contentHtml={item?.contentHtml} componentId='seo-content-section' />
                </div>
              </div>
            )

          case 'ComponentMoleculesHomeThumbnailBlock':
            return (
              <div
                className={CN('bg-white pt-[40px] lg:pt-[80px]', {
                  'pb-[40px] lg:pb-[80px]': isNextBlockNonWhiteBg,
                  '!pb-[80px]': isLastElement
                })}
                key={`ComponentMoleculesHomeThumbnailBlock-${item.HomeThumbnailBlockId}`}>
                <div className='container'>
                  <PopularProductCategories
                    actionButton={{
                      children: item?.Action?.DisplayText,
                      url: item?.Action?.URL
                    }}
                    categories={item?.Categories}
                    header={item?.HomeThumbnailBlockHeader}
                    componentId='popular-product-category-section'
                  />
                </div>
              </div>
            )

          default:
            return null
        }
      })}
    </div>
  )
}

/* Define the server-side props fetching function */
export const getServerSideProps: GetServerSideProps = withSessionSsr(async context => {
  /* Destructure query object from context */
  const { query } = context

  /* Get type and versionId from query, set default values if not provided */
  const type = query?.type || 'live'
  const versionId = query?.versionId || 0

  /* Fetch data for the home page based on type and versionId */
  const data = await getHomePageDetails({ type, versionId })

  const processContent = async (content: any) => {
    const processedContent = await remark()
      .use(remarkHtml, { sanitize: false })
      .use(remarkGfm)
      .process(content)

    return processedContent.toString()
  }

  const HomeTemplate = data?.HomeResponse?.data[0]?.attributes?.HomeTemplate

  let prepHomeTemplate = []

  if (HomeTemplate?.length) {
    prepHomeTemplate = await Promise.all(
      HomeTemplate?.map(async (item: any) => {
        if (item?.__typename === 'ComponentMoleculesHomeDealsBlock') {
          const productIds2 = item?.Products?.map((product: any) => product?.ProductID)
          const promotedProductsRes2 = await getDealProductsHome(context, productIds2, 4)
          const products = keepCmsSorting(productIds2, promotedProductsRes2?.data?.products || [])
          return {
            ...item,
            Products: products
          }
        } else if (item?.__typename === 'ComponentMoleculesSeoBlock') {
          const contentHtml = await processContent(item?.Content)
          return {
            ...item,
            contentHtml
          }
        } else {
          return item
        }
      })
    )
  }

  const metaDetails = data?.HomeResponse?.data[0]?.attributes?.MetaBlock

  const meta = {
    title: metaDetails?.MetaTitle ?? '',
    description: metaDetails?.MetaDescription ?? '',
    keywords: '',
    ogImage: {
      imageUrl: metaDetails?.OpenGraphImage?.data?.attributes?.provider_metadata?.public_id ?? '',
      alt: metaDetails?.OpenGraphImage?.data?.attributes?.alternativeText ?? ''
    }
  }

  /* Return props to be passed to the Page component */
  return {
    props: {
      meta,
      pageData: data || {},
      prepHomeTemplate
    }
  }
})

/* Export the Page component as the default export */
export default Page
